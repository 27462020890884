<template>
  <div>
    <NextMaterialSection learnMoreButtonMode="anchor" title="Rólunk" image-src="main2.jpg" />
    <OurHistorySection />
    <TitleOfSection1Section />
    <TitleOfSection2Section />
    <TitleOfSection3Section />
<!--    <WhoChooseUsSection />-->
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import NextMaterialSection from "@/components/NextMaterialSection.vue";
import OurHistorySection from "@/components/OurHistorySection.vue";
// import WhoChooseUsSection from "@/components/WhoChooseUsSection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";
import TitleOfSection1Section from "@/components/TitleOfSection1Section.vue";
import TitleOfSection2Section from "@/components/TitleOfSection2Section.vue";
import TitleOfSection3Section from "@/components/TitleOfSection3Section.vue";

export default {
  name: "AboutUs",
  components: {
    NextMaterialSection,
    OurHistorySection,
    // WhoChooseUsSection,
    CanWeHelpSection,
    TitleOfSection1Section,
    TitleOfSection2Section,
    TitleOfSection3Section,
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Rólunk',
      desc: 'Célunk, hogy korszerű technológiánkkal és anyagainkkal az ipar számára egy környezettudatos és innovatív alternatívát kínálhassunk.',
      keywords: 'Rólunk',
      ogType: 'website',
      ogTitle: 'Meshlin - Rólunk',
      ogImage: '/images/about-us0.jpg',
      ogImageWidth: 850,
      ogImageHeight: 635,
      ogDesc:'Célunk, hogy korszerű technológiánkkal és anyagainkkal az ipar számára egy környezettudatos és innovatív alternatívát kínálhassunk.'
    })
  }
};
</script>