<template>
  <div class="flex-column margin-lr-auto mw-1920 container--full">
    <div class="flex-row divider--resp flex-center-space-between container">
      <h5 class="text--white--big">{{ title }}</h5>
      <div id="wrapper" class="position--relative container--animation--resp">
        <div id="line"></div>
      </div>
    </div>

    <div
        v-for="product in products"
        :key="tr(product.id)"
        class="center margin-spec-top divider--resp margin-lr-auto container--920 flex-row-resp"
    >
      <div class="left-part--product dflex">
        <div class="position-relative margin-lr-auto divider--resp--none">
          <a :href="getHref(tr(product.productFamilyId), tr(product.id))"><img class='productPicture'
                                                                               :src="`/images/ProductTiles/${product.image}`"
                                                                               :alt="tr(product.name)"
          /></a>
          <div
              class="title--white-small--hero slide-gallery-shadow resp-display--none position-absolute flex-column bottom left">
           <span class="white-space--nowrap--md">{{ tr(product.name) }}
             <img v-if="tr(product.nameAddition)" class="margin-small--horizontal--icon"
                  src="@/assets/images/svgs/plus.svg" alt="plus icon"/></span>
            <span>
            {{ tr(product.nameAddition) }}
              <img class="margin-small--horizontal--icon" src="@/assets/images/svgs/info2.svg"  alt="info icon"/></span>
          </div>
        </div>
      </div>
      <div class="right-part--product">
        <div class="flex-column margin-left-50">
          <h4 class="title--white-small slide-gallery-shadow resp-display">
            {{ tr(product.name) }}
            <img v-if="product.nameAddition.hu.length" class="margin-small--horizontal--icon"
                 src="@/assets/images/svgs/plus.svg"/>
            {{ product.nameAddition && tr(product.nameAddition) }}
            <!--            <b-button v-b-popover.hover.top="tr(product.messagePopover)" class="btn btn&#45;&#45;popover">-->

            <!--              <img class="margin-small&#45;&#45;horizontal&#45;&#45;icon info-icon" src="@/assets/images/svgs/info2.svg"/>-->
            <!--            </b-button>-->
          </h4>
          <h6 class="text text-left margin-small--top--resp">
            {{ tr(product.description) }}
          </h6>
          <div class="navbar-arrow-animation">
            <a @click="setDataLayer('Részletek', 'Kattintás', product.productFamilyId.hu)"
               class="left text--navbar-link margin-small--top--details"
               :href="getHref(tr(product.productFamilyId), tr(product.id))"
            >{{ tr('details') }}
              <div
                  class="icon--navbar navbar-arrow"></div>
            </a></div>
        </div>
      </div>
    </div>
    <div class="divider--resp margin-spec-top container resp-display"></div>
  </div>
</template>

<script>
export default {
  props: {
    products: Array,
    title: String,
  },
  methods: {
    getHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.product`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId,
        },
      }).href;
    },
  },
};
</script>