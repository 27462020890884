<template>
  <div class="container--1110 mw-1920 margin-lr-auto flex-center-space-between flex-row-resp">
    <div class="right-part--faq align-self-baseline margin-large--top flex-column resp-display sticky-top">
      <div class="item"
           data-aos="fade-right"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="800"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <a href="#faq_intro" v-scroll-to="'#faq_intro'" class="divider--faq padding-none faq-btn-container" @click="selectedSection = 'intro'">
          <div :class="{'flex-row': true, 'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
            <img v-if="selectedSection !== 'intro'" class="icon--faq" src="@/assets/images/svgs/faq-intro.svg" alt="faq intro"/>
            <img v-if="selectedSection === 'intro'"  class="icon--faq" src="@/assets/images/svgs/faq-intro-a.svg" alt="faq intro selected"/>
            <p class="text--black margin-none align-self-center">
              {{ tr('intro') }}
            </p>
            <img class="icon--faq-arrow" src="@/assets/images/svgs/meshlin_arrow.svg" alt="Meshlin arrow"/>
          </div>
        </a>
        <a v-for="faqSection in $store.state.faqs" :key="faqSection.id" :href="`#faq_${faqSection.id}`" v-scroll-to="`#faq_${faqSection.id}`"
           class="divider--faq padding-none faq-btn-container" @click="selectedSection = faqSection.id">
          <div
              :class="{'flex-row': true, 'faq-background':true, 'faq-background-active':selectedSection === faqSection.id}">
            <img v-if="selectedSection !== faqSection.id" class="icon--faq" :src="require(`@/assets/images/svgs/${faqSection.icon}`)" :alt=" tr(faqSection.mainTitle)"/>
            <img v-if="selectedSection === faqSection.id" class="icon--faq" :src="require(`@/assets/images/svgs/${faqSection.iconActive}`)" :alt="`${tr(faqSection.mainTitle)}-active`"/>
            <p class="text--black margin-none align-self-center">
              {{ tr(faqSection.mainTitle) }}
            </p>
            <img class="icon--faq-arrow" src="@/assets/images/svgs/meshlin_arrow.svg" alt="Meshlin arrow"/>
          </div>
        </a>
      </div>
    </div>
    <div class="left-part--faq">
      <div class="item"
           data-aos="fade-left"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="800"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <div class="flex-row position-relative first-section">
          <input
              @click="setDataLayer('GYIK', 'kereses', searchKeyword)"
              class="searchInput focus:outline-none margin-medium--bottom margin-medium--bottom--resp search-bar margin-medium--top focus:shadow-outline border rounded-lg py-2 px-4 block w-3/4 appearance-none leading-normal"
              :placeholder="tr('searchPlaceholder')"
              type="text"
              v-model="searchKeyword"
              style="border-bottom: 1px solid #858288 !important"
          >
          <img class="icon--search" src="@/assets/images/svgs/search.svg" alt="search"/>
        </div>
        <b-card no-body class="collapse-menu resp-display--none">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-faq variant="info" class="faq-background--collapsePanel">
              {{ tr('faqMobileAccordion') }}
              <div class="expand-panel-icon--mobile"/>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-faq" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <a href="#faq_intro" class="divider--faq  padding-none faq-btn-container"
                   @click="selectedSection = 'intro'">
                  <div
                      :class="{'flex-row': true, 'faq-background':true, 'faq-background-active':selectedSection === 'intro'}">
                    <img v-if="selectedSection !== 'intro'" class="icon--faq" src="@/assets/images/svgs/faq-intro.svg" alt="faq intro"/>
                    <img v-if="selectedSection === 'intro'"  class="icon--faq" src="@/assets/images/svgs/faq-intro-a.svg" alt="faq intro selected"/>
                    <p class="text--black margin-none align-self-center">
                      {{ tr('intro') }}
                    </p>
                    <img class="icon--faq-arrow" src="@/assets/images/svgs/meshlin_arrow.svg" alt="meshlin arrow"/>
                  </div>
                </a>
                <a v-for="faqSection in $store.state.faqs" :key="faqSection.id" :href="`#faq_${faqSection.id}`"
                   class="divider--faq  padding-none faq-btn-container" @click="selectedSection = faqSection.id">
                  <div
                      :class="{'flex-row': true, 'faq-background':true, 'faq-background-active':selectedSection === faqSection.id}">
                    <img v-if="selectedSection !== faqSection.id" class="icon--faq" :src="require(`@/assets/images/svgs/${faqSection.icon}`)" :alt="tr(faqSection.mainTitle)"/>
                    <img v-if="selectedSection === faqSection.id" class="icon--faq" :src="require(`@/assets/images/svgs/${faqSection.iconActive}`)" :alt="`${tr(faqSection.mainTitle)-active}`"/>
                    <span class="text--black margin-none align-self-center">
                     {{ tr(faqSection.mainTitle) }}
                    </span>
                    <img class="icon--faq-arrow" src="@/assets/images/svgs/meshlin_arrow.svg" alt="meshlin arrow"/>
                  </div>
                </a>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <div v-if="!searchKeyword">
        <h2 id="faq_intro" class="title--black text-left">
          {{ tr('intro') }}
        </h2>
        <p class="text--black padding-none">
          {{ tr('faqText') }}
        </p>
        </div>
        <div v-show="!faqs.length">{{ tr('notFound') }}</div>
        <div :id="`faq_${faq.id}`" v-for="faq in faqs" :key="faq.id" class="margin-medium--bottom">
          <h2 class="title--black text-left">
            {{ tr(faq.mainTitle) }}
          </h2>
          <div class="accordion" role="tablist">
            <b-card v-for="question in faq.questions" :key="`${faq.id}_${question.id}`" no-body
                    class="mb-1 padding-none">
              <b-card-header header-tag="header" class="padding-none" role="tab">
                <b-button @click="setDataLayer('GYIK', 'kérdés lenyitás', tr(question.textTitle))"
                          v-b-toggle="`${faq.id}_${question.id}`" block variant="info"
                          class="text text-left padding-none--faq">
                  <div
                      class="expand-panel-icon center"></div>
                  {{ tr(question.textTitle) }}
                </b-button>
              </b-card-header>
              <b-collapse :id="`${faq.id}_${question.id}`" :visible="!!keyword" role="tabpanel">
                <b-card-body>
                  <b-card-text class="text-left padding-none">{{ tr(question.text) }}</b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
        <div class="position--relative margin-large--bottom">
          <img
              class="help-section-form--right--faq"
              src="../assets/images/svgs/form_5.svg"
          />
          <img
              class="help-section-form--right2--faq2"
              src="../assets/images/svgs/meshlin_main-vector.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchKeyword: undefined,
    selectedSection: undefined
  }),
  computed: {
    keyword() {
      return (this.searchKeyword ?? '').toLowerCase().trim();
    },
    faqs() {
      const _this = this;
      const match = trObj => _this.tr(trObj).toLowerCase().includes(_this.keyword);
      return this.$store.state.faqs
          .filter(f => !_this.keyword || f.questions.some(q => match(q.textTitle) || match(q.text)))
          .map(f => ({...f, questions: f.questions.filter(q => match(q.textTitle) || match(q.text))}));
    },
  }
}
</script>