import Vue from 'vue'
import Vuex from 'vuex'
import * as CONFIG from '@/config/init.js'
import colors from '../../public/data/colors.json'
import faqs from '../../public/data/faqs.json'
import fieldsOfUse from '../../public/data/fieldsOfUse.json'
import productFamilies from '../../public/data/productFamilies.json'
import areasOfUse from '../../public/data/areasOfUse.json'
import urlSlug from "@/utils/urlSlug";

Vue.use(Vuex)

productFamilies.forEach(productFamily => {
  productFamily.id = Object.entries(productFamily.name).reduce((acc, [key, value]) => {
    acc[key] = urlSlug(value);
    return acc;
  }, {});
  productFamily.products.forEach(product => {
    product.id = Object.entries(product.name).reduce((acc, [key, value]) => {
      acc[key] = urlSlug(`${value} ${(product.nameAddition ?? {})[key]}`);
      return acc;
    }, {})
    product.productFamilyId = productFamily.id;
    product.colors = (product.colorIds ?? []).map(colorId => colors.find(color => colorId === color.id));
    product.fieldsOfUse = (product.fieldOfUseIds ?? []).map(fieldOfUseId => fieldsOfUse.find(fieldOfUse => fieldOfUseId === fieldOfUse.id));
  });
});

const doesIntersect = (a, b) => {
  if (!a || !a.length || !b || !b.length) {
    return false;
  }
  return a.some(e => b.includes(e));
}

areasOfUse.forEach(areaOfUse => {
  areaOfUse.id = Object.entries(areaOfUse.name).reduce((acc, [key, value]) => {
    acc[key] = urlSlug(value);
    return acc;
  }, {});
  areaOfUse.fieldsOfUse = (areaOfUse.fieldOfUseIds ?? []).map(fieldOfUseId => fieldsOfUse.find(fieldOfUse => fieldOfUseId === fieldOfUse.id));
  areaOfUse.products = productFamilies.map(pf => pf.products).flat().filter(p => doesIntersect(areaOfUse.fieldOfUseIds, p.fieldOfUseIds));
});

export default new Vuex.Store({
  state: {
    apiCalls: {},
    needCookieSettings: true,
    faqs,
    areasOfUse,
    productFamilies,
    languageCode: CONFIG.defaultLanguageCode,
  },
  mutations: {
    language(state, lang) {
      state.languageCode = lang;
    }
  },
  actions: {},
  modules: {}
})
