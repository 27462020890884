<template>
  <div>
    <div class="flex-row-resp--md container justify-content-end">
      <div class="gallery-margin-right">
        <div class="item"
             data-aos="fade-down"
             data-aos-offset="-250"
             data-aos-delay="62"
             data-aos-duration="800"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <h2 class="title--black center padding-gallery-title mw-1920">
            {{ tr('galleryTitle') }}
          </h2>
        </div>
      </div>
      <b-button
          @click="open(); setDataLayer('galeria', 'megnyitás', areaOfUse.name.hu)"
                class="text--green bg-transparent white-space--nowrap dflex margin-none align-self-center padding-none">
        {{ tr('galleryButton') }}
        <img class="icon--navbar" src="@/assets/images/svgs/search-plus.svg" alt="search plus"/>
      </b-button>
    </div>
    <div class="item"
         data-aos="fade-up"
         data-aos-offset="-150"
         data-aos-delay="62"
         data-aos-duration="800"
         data-aos-easing="ease-out-cubic"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
      <div class="container--gallery flex-wrap max-width-100 ">
        <gallery :items="images" :origin="origin" @close="origin = null">
        </gallery>
        <div v-for="(image, index) in images"
             :key="index" class="tile-container--product" style="width: calc(25vw - (25vw - 25%)); height: 25vw">
          <div
              class="tile"
              @click="origin = index;  setDataLayer('galeria', 'megnyitás', areaOfUse.name.hu)"
              :style="{
        backgroundImage: 'url(' + image.href + ')',
        backgroundSize:'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'
      }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-blueimp-gallery/dist/vue-blueimp-gallery.css";
import gallery from "vue-blueimp-gallery";
import slashImageUrl from "@/assets/images/svgs/meshlin_main-vector-gallery.svg";

export default {
  props: {
  },
  components: {
    gallery,
  },
  data: function () {
    // return { id: image.src, href: url, markdown: image.title, description: elzevir.htmlizeSync(image.title || ""), reference: reference };
    return {
      origin: null,
    };
  },
  methods: {
    open() {
      this.origin = 0;
      this.setDataLayer('galeria', 'megnyitás', this.areaOfUse.name.hu);
    }
  },
  computed: {
    images() {
      return this.areaOfUse.gallery.map((image, index, array) => ({
        ...image,
        description: `<div class="flex-row margin-medium--bottom"><div class="image-gallery-text-container">${image.description}</div><div class="center image-gallery-number"><div class="text--gallery">${index + 1}</div><img class="galleryImg" src="${slashImageUrl}"><div class="text--gallery--narrow">${array.length}</div></div></div>`
      }));
    },
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(
          (areaOfUse) => this.tr(areaOfUse.id) === this.areaOfUseId
      );
    },
  }
};
</script>
