<template>
  <div>
    <ProductSection :products="availableProducts"/>
    <CanWeHelpSection />
    <ProductFamilySection2
      class="background-white white-title"
      info-width="narrow"
    />
  </div>
</template>


<script>
// @ is an alias to /src
import ProductFamilySection2 from "@/components/ProductFamilySection.vue";
import ProductSection from "@/components/ProductSection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";

export default {
  name: "Product",
  components: {
    ProductFamilySection2,
    ProductSection,
    CanWeHelpSection,
  },
  computed: {
    availableProducts() {
      return this.productFamily.products.filter(product => this.tr(product.id) !== this.productId)
    },
    productId() {
      return this.$router.currentRoute.params.productId;
    },
    product() {
      return this.productFamily.products.find(
          (product) => this.tr(product.id) === this.productId
      );
    },
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Termékek - ' + this.tr(this.product.name),
      desc: this.tr(this.product.description),
      keywords: 'Contact',
      ogType: 'website',
      ogTitle: 'Meshlin - Termékek - ' + this.tr(this.product.name),
      ogImage: '/images/ProductTiles/' + this.product.image,
      ogImageWidth: 400,
      ogImageHeight: 400,
      ogDesc:this.tr(this.product.description),
    })
  },
};
</script>