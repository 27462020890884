<template>
  <div class="container--1110  mw-1920 margin-lr-auto flex-center-space-between flex-column">
    <h2 id="faq_intro" class="title--black margin-large--top margin-medium--bottom text-center text-left">
      MESHLIN Composites Zrt. ADATKEZELÉSI TÁJÉKOZTATÓJA
    </h2>
    <p class="text--black padding-none">
      <strong>A Társaság az általa kezelt személyes adatok tekintetében biztosítani kívánja az adatkezelés jogszerűségét,
      fokozottan ügyel a személyes adatok védelmére, a kötelező rendelkezések betartására, a biztonságos és
        tisztességes adatkezelésre.</strong>
    </p>
    <p class="text--black ">
      <strong>A tájékoztató célja:</strong> hogy <strong>rögzítse a MESHLIN Composites Zrt. által alkalmazott adatvédelmi és –kezelési
      elveket,</strong> és az adatvédelmi, –kezelési politikáját, amelyet, mint adatkezelő <strong>magára nézve kötelező erővel ismer
      el.</strong>
    </p>
    <p class="text--black ">
      A Tájékoztató rendelkezésinek kialakításakor a Társaság különös tekintettel vette figyelembe az Európai
      Parlament és a Tanács 2016/679 Rendeletében (GDPR), az információs önrendelkezési jogról és az
      információszabadságról szóló 2011. évi CXII. törvény („Infotv.”), a Polgári Törvénykönyvről szóló 2013. évi V.
      törvény („Ptk.”) rendelkezéseit.
    </p>

    <h2 class="title--black text-left margin-medium--bottom">
      I. ADATKEZELŐ
    </h2>
<ul>
    <li class="text--black privacy-list-item">
      Cégnév: <strong>MESHLIN Composites Zrt.</strong>
    </li>

    <i class="text--black privacy-list-item">
      Székhely: 9027 Győr, Puskás Tivadar utca 10.
    </i>

    <li class="text--black privacy-list-item">
      Cégjegyzékszám: Cg. 08 10 001877
    </li>

    <li class="text--black privacy-list-item">
      Adószám: 24913140-2-08
    </li>

    <li class="text--black privacy-list-item">
      E-mail cím:<a href="mailto:meshlin@meshlin.com">meshlin@meshlin.com</a></li>

    <li class="text--black privacy-list-item">
      Tel.: +30-96-326-474
    </li>

    <li class="text--black privacy-list-item ">
      A szervezet adatvédelmi tisztviselőt nem alkalmaz.
    </li>
</ul>


    <h2 class="title--black text-left margin-medium--bottom">
      FOGALOM MEGHATÁROZÁSOK
    </h2>

    <p class="text--black ">
      <strong>Adatkezelés:</strong> az alkalmazott eljárástól függetlenül a Személyes adatokon végzett bármely művelet vagy műveletek összessége, így különösen a Személyes adatok gyűjtése, rögzítése, rendszerezése, tagolása, tárolása, átalakítása, megváltoztatása, felhasználása, lekérdezése, betekintése, felhasználása, közlése, továbbítása, terjesztése, vagy egyéb módon hozzáférhetővé tétele, nyilvánosságra hozatala, összehangolása vagy összekapcsolása, korlátozása, törlése és megsemmisítése.    </p>

  <p class="text--black w-100">
    <strong>Az adatkezelő által végzett tevékenység:</strong> kapcsolatfelvétel során közölt személyes adatok kezelése  </p>

<p class="text--black ">
  Az <strong>adatkezelés</strong> jogalapja: az érintett hozzájárulása.
  A <strong>16. életévüket be nem töltött személyek</strong> az adatkezeléshez kizárólag a felettük szülői felügyeletet gyakorló személy útján vagy az ő engedélyével járulhatnak hozzá.
</p>


    <h2 class="title--black text-left margin-medium--bottom">
      A honlap internetes látogatói adatainak kezelése
    </h2>

    <h2 class="title--black text-left margin-medium--bottom">
      AZ ADATKEZELÉS ELVEI
    </h2>

    <p class="text--black ">
      Az Adatkezelő a Személyes adatokat a <strong>jóhiszeműség és a tisztesség</strong> és <strong>átláthatóság elveinek</strong>, valamint a <strong>hatályos jogszabályok és Jelen Tájékoztató rendelkezéseinek megfelelően kezeli.</strong>
    </p>

    <p class="text--black w-100">
      Az Adatkezelő a Személyes adatokat az érintett Felhasználó hozzájárulása alapján, és <strong>kizárólag célhoz kötötten használja fel.</strong>
</p>

    <p class="text--black ">
      A kezelt Személyes adatok köre arányban áll az adatkezelés céljával, azon nem terjeszkedhet túl.
      Minden olyan esetben, ha a Személyes adatokat az Adatkezelő az eredeti adatfelvétel céljától eltérő célra kívánja felhasználni, erről a Felhasználót tájékoztatja, és ehhez előzetes, kifejezett hozzájárulását megszerzi, illetőleg lehetőséget biztosít a számára, hogy a felhasználást megtiltsa.
 </p>

    <p class="text--black w-100">
      Adatkezelő a neki megadott Személyes adatokat nem ellenőrzi. <strong>A megadott Személyes adatok megfelelőségért kizárólag az azt megadó személy felel.</strong>
</p>

    <p class="text--black ">A 16. életévét be nem töltött személy érintett Személyes adatai csak a felette szülői felügyeletet gyakorló nagykorú személy hozzájárulása esetén kezelhetők. Az adatkezelőnek nem áll módjában a hozzájáruló személy jogosultságát, illetve a nyilatkozatának tartalmát ellenőrizni, így a Felhasználó illetve a felette szülői felügyeletet gyakorló személy szavatol azért, hogy a hozzájárulás megfelel a jogszabályoknak. Hozzájáruló nyilatkozat hiányában az Adatkezelő 16. életévét be nem töltött érintettre vonatkozó Személyes adatot – IP cím kivételével, amelynek rögzítésére az internetes szolgáltatások jellegéből adódóan automatikusan sor kerül – nem gyűjt.</p>
    <p class="text--black ">Az adatkezelő az általa kezelt Személyes adatokat a jelen tájékozatóban meghatározott Adatfeldolgozókon, a Társaság munkavállalóin, valamint egyes esetekben a Külső Szolgáltatókon kívül harmadik félnek át nem adja.</p>
    <p class="text--black ">Az adatkezelő bizonyos esetekben - hivatalos bírósági, rendőrségi megkeresés, jogi eljárás szerzői-, vagoni- illetve egyéb jogsértés vagy ezek alapos gyanúja miatt harmadik személy számára hozzáférhetővé teszi az érintett Felhasználó elérhető Személyes adatait.</p>
    <p class="text--black ">Az adatkezelő rendszere a Felhasználó aktivitásáról adatokat gyűjthet, melyek nem kapcsolhatók össze a Felhasználók által a regisztrációkor megadott egyéb adatokkal, sem más honlapok vagy szolgáltatások igénybevételekor keletkező adatokkal.</p>
    <p class="text--black ">Az adatkezelő gondoskodik a Személyes adatok biztonságáról, megteszi azokat a technikai és szervezési intézkedéseket, és kialakítja azokat az eljárási szabályokat, amelyek biztosítják, hogy a felvett, tárolt, illetve kezelt adatok védettek legyenek, illetőleg megakadályozza azok véletlen elvesztését, jogtalan megsemmisülését, jogosulatlan hozzáférését, jogosulatlan felhasználást és jogosulatlan megváltoztatását, jogosulatlan terjesztését.
    </p>
    <h2 class="title--black text-left margin-medium--bottom">
      TÁJÉKOZTATÁS AZ ÉRINTETT JOGAIRÓL
    </h2>



    <p class="text--black "><strong>Előzetes tájékozódáshoz való jog</strong>
      Az érintett jogosult arra, hogy az adatkezeléssel összefüggő tényekről és információkról az adatkezelés megkezdését megelőzően tájékoztatást kapjon.
      (Rendelet 13-14. cikk)</p>
    <p class="text--black "><strong>Az érintett hozzáférési joga</strong>
      Az érintett jogosult arra, hogy az Adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és a Rendeletben meghatározott kapcsolódó információkhoz hozzáférést kapjon. (Rendelet 15. cikk). </p>
    <p class="text--black "><strong>A helyesbítéshez való jog</strong>
      Az érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat. Figyelembe véve az adatkezelés célját, az érintett jogosult arra, hogy kérje a hiányos személyes adatok – egyebek mellett kiegészítő nyilatkozat útján történő – kiegészítését. (Rendelet 16. cikk).</p>
    <p class="text--black "><strong>A törléshez való jog („az elfeledtetéshez való jog”)</strong>
      Az érintett jogosult arra, hogy kérésére az Adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, az Adatkezelő pedig köteles arra, hogy az érintettre vonatkozó személyes adatokat indokolatlan késedelem nélkül törölje, ha a Rendeltben meghatározott indokok valamelyike fennáll.
      (Rendelet 17. cikk)</p>
    <p class="text--black "><strong>Az adatkezelés korlátozásához való jog</strong>
      Az érintett jogosult arra, hogy kérésére az Adatkezelő korlátozza az adatkezelést ha a rendeltben meghatározott feltételek teljesülnek. (Rendelet 18. cikk)</p>
    <p class="text--black "><strong>A személyes adatok helyesbítéséhez vagy törléséhez, illetve az adatkezelés korlátozásához kapcsolódó értesítési kötelezettség</strong>
      Az Adatkezelő minden olyan címzettet tájékoztat valamennyi helyesbítésről, törlésről vagy adatkezelés-korlátozásról, akivel, illetve amellyel a személyes adatot közölték, kivéve, ha ez lehetetlennek bizonyul, vagy aránytalanul nagy erőfeszítést igényel. Az érintettet kérésére az Adatkezelő tájékoztatja e címzettekről.
      (Rendelet 19. cikk)</p>
    <p class="text--black "><strong>Az adathordozhatósághoz való jog</strong>
      A Rendeletben írt feltételekkel az érintett jogosult arra, hogy a rá vonatkozó, általa egy Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik Adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná az az Adatkezelő, amelynek a személyes adatokat a rendelkezésére bocsátotta. (Rendelet 20. cikk) </p>
    <p class="text--black "><strong>A tiltakozáshoz való jog</strong>
      Az érintett jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak a Rendelet 6. cikk (1) bekezdésének e) pontján (az adatkezelés közérdekű vagy az Adatkezelőre ruházott közhatalmi jogosítvány gyakorlásának keretében végzett feladat végrehajtásához szükséges) vagy f) pontján (az adatkezelés az Adatkezelő vagy egy harmadik fél jogos érdekeinek érvényesítéséhez szükség.(Rendelet 21. cikk)</p>
    <p class="text--black "><strong>Automatizált döntéshozatal egyedi ügyekben, beleértve a profilalkotást</strong>
      Az érintett jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag automatizált adatkezelésen – ideértve a profilalkotást is – alapuló döntés hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen jelentős mértékben érintené. (Rendelet 22. cikk)</p>
    <p class="text--black "><strong>Korlátozások</strong>
      Az Adatkezelőre vagy adatfeldolgozóra alkalmazandó uniós vagy tagállami jog jogalkotási intézkedésekkel korlátozhatja a 12–22. cikkben és a 34. cikkben foglalt, valamint a 12–22. cikkben meghatározott jogokkal és kötelezettségekkel összhangban. (Rendelet 23. cikk)</p>
    <p class="text--black "><strong>Az érintett tájékoztatása az adatvédelmi incidensről</strong>
      Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár a természetes személyek jogaira és szabadságaira nézve, az Adatkezelő indokolatlan késedelem nélkül tájékoztatja az érintettet az adatvédelmi incidensről. (Rendelet 34. cikk)</p>
    <p class="text--black ">
      <strong>A felügyeleti hatóságnál történő panasztételhez való jog (hatósági jogorvoslathoz való jog)</strong>
      Az érintett jogosult arra, hogy panaszt tegyen egy felügyeleti hatóságnál – különösen a szokásos tartózkodási helye, a munkahelye vagy a feltételezett jogsértés helye szerinti tagállamban –, ha az érintett megítélése szerint a rá vonatkozó személyes adatok kezelése megsérti a Rendeletet. (Rendelet 77. cikk)
    </p>
    <p class="text--black "><strong>A felügyeleti hatósággal szembeni hatékony bírósági jogorvoslathoz való jog</strong>
      Minden természetes és jogi személy jogosult a hatékony bírósági jogorvoslatra a felügyeleti hatóság rá vonatkozó, jogilag kötelező erejű döntésével szemben, vagy ha a felügyeleti hatóság nem foglalkozik a panasszal, vagy három hónapon belül nem tájékoztatja az érintettet a benyújtott panasszal kapcsolatos eljárási fejleményekről vagy annak eredményéről. (Rendelet 78. cikk)</p>
    <p class="text--black "><strong>Az adatkezelővel vagy az adatfeldolgozóval szembeni hatékony bírósági jogorvoslathoz való jog</strong>
      Minden érintett hatékony bírósági jogorvoslatra jogosult, ha megítélése szerint a személyes adatainak e rendeletnek nem megfelelő kezelése következtében megsértették az e rendelet szerinti jogait. (Rendelet 79. cikk)</p>

    <h2 class="title--black text-left margin-medium--bottom">
      Mik azok a sütik és hogyan történik a kezelésük?
    </h2>

    <p class="text--black ">A sütik (cookie-k) olyan kisméretű adatfájlok (a továbbiakban: sütik), amelyek a weboldalon keresztül a weboldal használatával kerülnek az Ön számítógépére úgy, hogy azokat az Ön internetes böngészője menti le és tárolja el. A leggyakrabban használt internetes böngészők (Chrome, Firefox, stb.) többsége alapbeállításként elfogadja és engedélyezi a sütik letöltését és használatát, az viszont már Öntől függ, hogy a böngésző beállításainak módosításával ezeket visszautasítja vagy letiltja, illetve Ön a már a számítógépen lévő eltárolt sütiket is tudja törölni. A sütik használatáról az egyes böngészők „súgó” menüpontja nyújt bővebb tájékoztatást. </p>
    <p class="text--black w-100 ">Az adatkezelő nem alkalmaz és nem is engedélyez olyan sütiket, amelyek segítségével harmadik személyek az Ön hozzájárulása nélkül adatot gyűjthetnek. </p>
    <p class="text--black ">A sütik elfogadása nem kötelező, az Adatkezelő azonban nem vállal azért felelősséget, ha sütik engedélyezése hiányában a weblap esetleg nem az elvárt módon működik.</p>
    <p class="text--black ">A cookie kezelés jogalapja a weboldal látogatójának hozzájárulása, a vonatkozó Rendelet 6. cikk (1) bekezdés a) pontja alapján. Amennyiben nem fogadja el a cookie-k használatát, akkor a 3.2.3. pontban felsorolt weboldal bizonyos funkciói nem lesznek elérhetőek a weboldal használata során, illetve előfordulhat, hogy bizonyos funkciók hibásan működnek.
      A cookie-k törléséről bővebb információt a gyakoribb böngészők tekintetében az alábbi linkeken találhat:</p>
    <ul>
      <li class="text--black privacy-list-item">Firefox: Weboldalak által elhelyezett sütik törlése a számítógépről</li>
      <li class="text--black privacy-list-item">>Chrome: Clear cache & cookies</li>
      <li class="text--black privacy-list-item">>Safari: Manage cookies and website data in Safari on Mac</li>
    </ul>

    <p class="text--black ">A honlap látogatása során a honlapon elhelyezett Google Analytics az Ön számítógépén sütiket helyez el, ez az alkalmazás a honlaplátogatási szokásait tartalmazza, ez a cég jogos érdeke, az adatokat a Google LLC. is megkapja. Az adatok törlésig tárolódnak számítógépén. Ha nem szeretné az analitikai süti elhelyezését, akkor a böngésző-beállításokkal ezt tilthatja vagy a Google által biztosított böngésző bővítménnyel ezt korlátozhatja: https://tools.google.com/dlpage/gaoptout.</p>
    <p class="text--black ">Az olyan közösségi hálózatok, mint például a Facebook, az általuk biztosított funkciók, mint a lájkolási, megosztási lehetőségek további sütiket  helyeznek el, ezek a honlap-látogatási szokásait tartalmazzák, ez a cég jogos érdeke, az adatokat a közösségimédia-szolgáltatók is megkapják. Az adatok törlésig tárolódnak számítógépén. Ha Ön ezen közösségi platformokba be van jelentkezve, a közösségi médium által biztosított beépülő szolgáltatások automatikusan aktivizálódnak. Amennyiben Ön nem szeretné ezeket a közösségimédia-sütiket és a hozzá tartozó egyéb funkciókat, a sütik elhelyezését tilthatja böngészőjében, illetve kijelentkezhet közösségimédia-fiókjából.</p>

    <h2 class="title--black align-self-baseline text-left margin-medium--bottom">Cookie lista</h2>
<div class="mw-100 privacy-table-container ">
    <b-table class="margin-medium--bottom" striped :items="items" :fields="fields"></b-table>
</div>

    <h2 class="title--black text-left margin-medium--bottom">AZ ADATKEZELÉSI TÁJÉKOZTATÓ MÓDOSÍTÁSA</h2>
    <p class="text--black w-100">Az Adatkezelő fenntartja magának a jogot, hogy jelen Tájékoztatót egyoldalú döntésével bármikor módosítsa.</p>

    <h2 class="title--black text-left margin-medium--bottom">JOGORVOSLATHOZ VALÓ JOG</h2>
    <p class="text--black ">Amennyiben az érintett úgy véli, hogy Adatkezelő az adatkezelése során megsértette a személyes adatok védelméhez való jogát, az irányadó jogszabályok szerint jogorvoslattal élhet a hatáskörrel rendelkező szerveknél, azaz panasszal élhet a NAIH-nál (cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.), vagy az illetékes bírósághoz fordulhat. Adatkezelő vállalja, hogy ezen eljárások során az érintett a bírósággal vagy a NAIH-hal mindenben együttműködik, az adatkezelésre vonatkozó adatokat az érintett bíróság vagy a NAIH részére kiadja.</p>
    <p class="text--black ">Adatkezelő kötelezettséget vállal arra is, hogy az érintett személyes adatainak jogellenes kezelésével vagy adatbiztonsági követelményeinek megszegésével okozott kárt megtéríti. Az érintett személyiségi jogának megsértése esetén az érintett sérelemdíjat követelhet. Adatkezelő mentesül a felelősség alól, ha a kárt az adatkezelés körén kívül eső elháríthatatlan ok idézte elő, továbbá ha a kár vagy a személyiségi jog megsértésével okozott jogsérelem az érintett szándékos vagy súlyosan gondatlan magatartásából származik.</p>

    <h2 class="title--black text-left">IX. VEGYES RENDELKEZÉSEK</h2>
    <p class="text--black ">Adatkezelő kötelezettséget vállal arra, hogy tevékenységével kapcsolatos minden adatkezelés megfelel a jelen tájékoztatóban, valamint a hatályos jogszabályokban meghatározott elvárásoknak.</p>

    <p class="text--black text-left left"><strong>Meshlin Composites Zrt.</strong></p>

    <p class="text--black left"> 2021.08.16.</p>




    <div class="accordion" role="tablist">

    </div>
    <div class="position--relative margin-large--bottom">
      <img
          class="help-section-form--right--faq"
          src="../assets/images/svgs/form_5.svg"
      />
      <img
          class="help-section-form--right2--faq2"
          src="../assets/images/svgs/meshlin_main-vector.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        {
          key: 'Süti_neve',
        },
        {
          key: 'Üzemeltető',
        },
        {
          key: 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?',
          label: 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?',
        },
        {
          key: 'Típusa',
        },
        {
          key: 'Élettartam',
        }
      ],
      items: [
        { Süti_neve: 'required_cookie', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Szükséges', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Tárolja a felhasználó által elfogadott sütik státuszát.', Élettartam: '30 nap' },
        { Süti_neve: 'statistic_cookie', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Szükséges', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Tárolja a felhasználó által elfogadott sütik státuszát.', Élettartam: '30 nap' },
        { Süti_neve: 'marketing_cookie', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Szükséges', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Tárolja a felhasználó által elfogadott sütik státuszát.', Élettartam: '30 nap' },
        { Süti_neve: '_ga', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Felhasználók és látogatások számának mérése, megkülönböztetése.', Élettartam: '2 év' },
        { Süti_neve: '_gid', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Felhasználók és látogatások számának mérése, megkülönböztetése.', Élettartam: '24 óra' },
        { Süti_neve: '_gat', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Leszabályozza az indított kérések számát a böngészőből, így javítva a teljesítményt.', Élettartam: '1 perc' },
        { Süti_neve: '_dc_gtm_<property-id>', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Leszabályozza az indított kérések számát a böngészőből, így javítva a teljesítményt.', Élettartam: '1 perc' },
        { Süti_neve: 'AMP_TOKEN', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Ügyfélazonosításhoz szükséges token-t tárol, ami különböző műveletekhez lehet szükséges. ', Élettartam: '30 mp-től kezdve 1 évig' },
        { Süti_neve: '_gac_<property-id>', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Statisztikai', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'A felhasználó kampányával kapcsolatos adatot tárol.' +
              'Ha a Google Analytics és a Google Ads fiókok össze vannak kapcsolva, akkor a Google Ads ezt az adatot veszi figyelmbe a weboldal konverziós tag-oknál.', Élettartam: '90 nap' },
        { Süti_neve: 'Google Analytics remarketing azonosító', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Releváns hirdetések megjelenítése', Élettartam: '540 nap' },
        { Süti_neve: 'Google Ads konverziós kód', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Releváns hirdetések megjelenítése', Élettartam: '540 nap' },
        { Süti_neve: 'Google Ads remarketing kód', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Releváns hirdetések megjelenítése', Élettartam: '540 nap' },
        { Süti_neve: 'Facebook konverziós kód', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Releváns hirdetések megjelenítése', Élettartam: '540 nap' },
        { Süti_neve: 'Facebook remarketing kód', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Releváns hirdetések megjelenítése', Élettartam: '540 nap' },
        { Süti_neve: '_fbp', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Felhasználók és látogatások számának mérése, megkülönböztetése.', Élettartam: '90 nap' },
        { Süti_neve: '_fbc', Üzemeltető: 'Weboldal üzemeltetője', Típusa: 'Marketing', 'Miért szükséges a weboldal számára és milyen funkciót nyújt a felhasználó részére? Milyen adatokhoz férhet hozzá?': 'Böngészési eszköz információk.', Élettartam: '4 év' },
      ]
    }
  }
}
</script>