<template>
  <div id="app">
    <div v-show="!fullscreenRoutes.includes($route.name)" class="padding-none--navbar nav-bar">
      <NavBar/>
    </div>
    <router-view/>
    <div></div>
    <div v-show="!fullscreenRoutes.includes($route.name)" class="footer">
      <Footer :open-cookie-settings="openCookieSettings"/>
    </div>
    <CookiePolicy class="cookie-top-position" :openCookieModal="openCookieModal" :open-cookie-changes="openCookieChanges" :close-cookie-modal="closeCookieSettings"/>
  </div>
</template>


<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import CookiePolicy from "@/views/CookiePolicy.vue";
import {languages} from "@/config/init.js"

export default {
  name: "Home",
  data(){
    return {
      openCookieModal: undefined,
      openCookieChanges: false
    }
  },
  components: {
    CookiePolicy,
    NavBar,
    Footer,
  },
  watch: {
    $route(to) {
      this.processRoute(to.path);
      this.deleteMetaTags();
      // this.setDataLayer('Navigation','routechange','empty');
      window.dataLayer.push({
        'event': 'eventNavigation',
        'category': '',
        'action': '',
        'label': ''
      });
    },
  },
  methods: {
    openCookieSettings(openCookieChanges){
      this.openCookieModal = true;
      this.openCookieChanges = openCookieChanges || false;
    },
    closeCookieSettings(){
      this.openCookieModal = false;
    },
    processRoute: function (path) {
      this.$store.state.languageCode = this.getRouteLanguage(path);
    },
  },
  computed: {
    fullscreenRoutes: () => {
      return ['error404', ...languages.map(l => `${l.code}.cookiePolicy`)];
    }
  }
};
</script>


<style lang="scss">
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
