<template>
  <div
      class="main-section--second container resp-padding-top-80 divider--areaOfUse background-white center flex-column">
    <div class="container--730">
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="-250"
           data-aos-delay="62"
           data-aos-duration="800"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <h2 class="title--black center--resp margin-spec-top margin-spec-bottom">
          {{ tr('areaOfUse') }}
        </h2>
      </div>
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="-300"
           data-aos-delay="102"
           data-aos-duration="1200"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <p class="text--main-text--black center--text margin-medium--top margin-spec-bottom">
          {{ tr(areaOfUse.description) }}
        </p>
      </div>
    </div>
    <div class="divider container--composite-tiles padding-large-bottom center">
      <div class="flex-row-resp">
        <div class="item"
             data-aos="fade-right"
             data-aos-offset="-200"
             data-aos-delay="92"
             data-aos-duration="1400"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column"
          ><img
              class="tile--composite-white"
              src="../assets/images/svgs/graf1-2.svg"
          /><span class="text--black--narrow margin-small--top">{{ tr('advantageAOU1') }}</span></div
          >
        </div>
        <div class="flex-row-resp">
          <div class="item"
               data-aos="fade-right"
               data-aos-offset="-200"
               data-aos-delay="62"
               data-aos-duration="700"
               data-aos-easing="ease-out-cubic"
               data-aos-mirror="true"
               data-aos-once="true"
               data-aos-anchor-placement="top-center">
            <div class="center flex-column">
              <img
                  class="tile--composite-white"
                  src="../assets/images/svgs/graf3-2.svg"
              /><span class="text--black--narrow margin-small--top">{{ tr('advantageAOU3') }}</span></div
            >
          </div>
        </div>
        <div class="item"
             data-aos="fade-left"
             data-aos-offset="-200"
             data-aos-delay="62"
             data-aos-duration="700"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column">
            <img
                class="tile--composite-white"
                src="../assets/images/svgs/graf2-2.svg"
            /><span class="text--black--narrow margin-small--top">{{ tr('advantageAOU2') }}</span></div
          >
        </div>
        <div class="item"
             data-aos="fade-left"
             data-aos-offset="-200"
             data-aos-delay="62"
             data-aos-duration="1400"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column">
            <img
                class="tile--composite-white"
                src="../assets/images/svgs/graf4-2.svg"
            /><span class="text--black--narrow margin-small--top">{{ tr('advantageAOU4') }}</span></div
          >
        </div>
      </div>
    </div>
    <div class="container--730">
      <div class="item"
           data-aos="fade-up"
           data-aos-offset="-350"
           data-aos-delay="62"
           data-aos-duration="800"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <p class="text--main-text center--text margin-spec-bottom margin-medium--top">
          {{ tr(areaOfUse.description2) }}
        </p>
      </div>
    </div>
    <div class="flex-row margin-min-10 flex-wrap justify-content-center ">
      <div
          v-for="fieldOfUse in areaOfUse.fieldsOfUse"
          :key="fieldOfUse.id"
      >
        <div class="flex-column">
          <img class="margin-small--horizontal--10 service-icon--130" :src="`/images/fieldsOfUse/${fieldOfUse.image}`" :alt="tr('areaOfUse')" />
          <span class="center text--footer--wide mw-fit-content margin-medium--bottom">{{
              tr(fieldOfUse.name)
            }}</span>
        </div>
      </div>
    </div>
    <div class="flex-row-resp margin-spec-top">
      <router-link
          @click="setDataLayer('GYIK', 'Kattintás', 'felhasznalasi terulet aloldal')"
          class="btn btn--gray button-margin"
          :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_4'))
        "
      ><img
          class="icon"
          src="../assets/images/svgs/question-circle.svg"
      />{{ tr('faqButton') }}
      </router-link>
<!--      <a @click="setDataLayer('Katalógus letöltése', 'kattintás', areaOfUse.name.hu)"-->
<!--         :href="`/sample-3pp-${$store.state.languageCode}.pdf`" target="_blank" class="btn btn&#45;&#45;gray button-margin"-->
<!--      ><img class="icon" src="@/assets/images/svgs/comet.svg"/>{{ tr('catalogButton') }}</a-->
<!--      >-->
      <router-link
          @click="setDataLayer('Lépjen kapcsolatba velünk', 'Kattintás', areaOfUse.name.hu)"
          class="btn btn--green margin-medium--btn margin-lr-auto"
          :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_5'))
        "
      ><img
          class="icon"
          src="../assets/images/svgs/envelope-open.svg"
      />{{ tr('contactButton') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(
          (areaOfUse) => this.tr(areaOfUse.id) === this.areaOfUseId
      );
    },
  },
};
</script>
