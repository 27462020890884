<template>
  <div class="background-white">
    <FaqSection/>
    <CanWeHelpSection/>
  </div>
</template>


<script>
// @ is an alias to /src
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";
import FaqSection from "@/components/FAQSection";

export default {
  name: "FAQ",
  components: {
    CanWeHelpSection,
    FaqSection,
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Gyakran ismételt kérdések',
      desc: 'Üdvözöljük a MeshLin Composites Zrt. honlapjának „Gyakran ismételt kérdések” menüjében. Összegyűjtöttünk néhányat a leggyakrabban előforduló kérdések és válaszok közül, amelyek segítenek eligazodni termékeink között.',
      keywords: 'FAQ',
      ogType: 'website',
      ogTitle: 'Meshlin - Gyakran ismételt kérdések',
      ogImage: '/images/seo-logo.jpg',
      ogImageWidth: 600,
      ogImageHeight: 315,
      ogDesc:'Üdvözöljük a MeshLin Composites Zrt. honlapjának „Gyakran ismételt kérdések” menüjében. Összegyűjtöttünk néhányat a leggyakrabban előforduló kérdések és válaszok közül, amelyek segítenek eligazodni termékeink között.'
    })
  },
}
</script>
