<template>
  <div class="background-white--gallery">
    <AreaOfUseHero />
    <AreaOfUseMain />
    <GalleryAreaOfUse :areaOfUse="areaOfUse"/>
    <ProductsInfoTileSection title="Kapcsolódó termékek" class="background-black padding-big-resp-top" :products="productsForAreaOfUse"  />
    <div class="container--tiles ">
    <ProductFamilySectionInfo  class="background-black margin-lr-auto"/>
  </div>
    <AreaOfUseSection filter-current-family />
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import ProductsInfoTileSection from "@/components/ProductsInfoTileSection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection";
import AreaOfUseMain from "@/components/AreaOfUseMain";
import AreaOfUseHero from "@/components/AreaOfUseHero";
import ProductFamilySectionInfo from "@/components/ProductFamilySectionInfo";
import AreaOfUseSection from "@/components/AreaOfUseSection";
import GalleryAreaOfUse from "@/components/GalleryAreaOfUse";

export default {
  name: "AreaOfUse",
  components: {
    GalleryAreaOfUse,
    AreaOfUseHero,
    AreaOfUseMain,
    ProductsInfoTileSection,
    ProductFamilySectionInfo,
    AreaOfUseSection,
    CanWeHelpSection
  },
  computed: {
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(areaOfUse => this.tr(areaOfUse.id) === this.areaOfUseId)
    },
    productsForAreaOfUse() {
      return this.$store.state.productFamilies.map(p => p.products).flat().filter(p => p.class && p.class.includes(this.areaOfUse.class))
    },
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Felhasználási területek - ' + this.tr(this.areaOfUse.name),
      desc: this.tr(this.areaOfUse.description),
      keywords: 'area',
      ogType: 'website',
      ogTitle: 'Meshlin - Felhasználási területek\n - '+ this.tr(this.areaOfUse.name),
      ogImage: '/images/AreaOfUseTiles/' + this.areaOfUse.image,
      ogImageWidth: 370,
      ogImageHeight: 370,
      ogDesc:this.tr(this.areaOfUse.description)
    })
  }
};
</script>