<template>
  <div class="main-section--first--contact">


    <!--    <b-modal v-if="modal" hide-header ok-only visible id="modal-footer-sm" title="BootstrapVue" button-size="sm">-->
    <!--      <p class="my-2">Köszönjük, az üzenetét megkaptuk!<br/>-->
    <!--        Hamarosan felvesszük Önnel a kapcsolatot.</p>-->
    <!--    </b-modal>-->


    <div class="flex-row-resp--md container margin-lr-auto">
      <div class="left-part--contact">
        <div>
          <h1 class="title--big padding-none--vertical center--resp">
            {{ tr("contact") }}
          </h1>
          <div class="container--animation margin---resp center--resp-margin">
            <div id="wrapper">
              <div id="line"></div>
            </div>
          </div>
          <div class="center--resp flex-column">
            <h6 class="text--contact text-left margin-medium--bottom-30">
              Meshlin Composites Zrt.
            </h6>
            <div class="text-left">
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img class="icon--contact" src="@/assets/images/svgs/gps.svg"/>
                9027 Győr, Puskás Tivadar u. 10.
              </h6>
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img
                    class="icon--contact"
                    src="@/assets/images/svgs/phone.svg"
                />
                <a @click="setDataLayer('kapcsolat_menupont', 'telefonszam_gombra_kattintas', '')"
                   class="text text-left" href="tel:0036-9-632-6474">+36 96 326 474</a>
              </h6>
              <h6
                  class="text text-left padding-none--resp margin-medium--bottom-30"
              >
                <img
                    class="icon--contact"
                    src="@/assets/images/svgs/envelope-open-green.svg"
                />
                <a @click="setDataLayer('kapcsolat_menupont', 'email_gombra_kattintas', '')" class="text text-left"
                   href="mailto: meshlin@meshlin.com">meshlin@meshlin.com</a>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part--faq">
        <div
            class="item"
            data-aos="fade-left"
            data-aos-offset="-150"
            data-aos-delay="62"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
        >
          <h2 class="title--white-small--faq resp-text-align-center text-left">
            {{ tr("sendMessage2") }}
          </h2>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form novalidate @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                  name="Name"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-1"
                    class="position-relative"
                    label-for="example-input-1"
                >
                  <b-form-input
                      :required="true"
                      class="margin-medium--btn"
                      id="input-live-1"
                      v-model="form.name"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-live-1-feedback"
                      :placeholder="tr('name')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-1-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorName")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-2"
                    class="position-relative"
                    label-for="example-input-2"
                >
                  <b-form-input
                      :required="true"
                      type="email"
                      class="margin-medium--btn"
                      id="input-live-2"
                      v-model="form.email"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-2-live-feedback"
                      :placeholder="tr('email')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-2-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorEmail")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Subject"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-3"
                    class="position-relative"
                    label-for="example-input-3"
                >
                  <b-form-input
                      :required="true"
                      class="margin-medium--btn"
                      id="input-live-3"
                      v-model="form.subject"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-3-live-feedback"
                      :placeholder="tr('subject')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-3-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorSubject")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Company"
                  :rules="{ required: false, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-4"
                    class="position-relative"
                    label-for="example-input-4"
                >
                  <b-form-input
                      class="margin-medium--btn"
                      v-model="form.company"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-4-live-feedback"
                      :placeholder="tr('company')"
                      trim
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Message"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-5"
                    class="position-relative"
                    label-for="example-input-5"
                >
                  <b-form-input
                      :required="true"
                      class="margin-medium--btn"
                      id="input-live-5"
                      v-model="form.message"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-5-live-feedback"
                      :placeholder="tr('message')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-5-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorMessage")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div v-if="sentMessage" class="sent-message-container d-flex text-left"><img class="icon--faq"
                                                                                           src="@/assets/images/svgs/faq-2-a.svg">
                <div>Köszönjük, az üzenetét megkaptuk!<br/>
                  Hamarosan felvesszük Önnel a kapcsolatot.
                </div>
              </div>

              <!--              <validation-provider-->
              <!--                  name="Checkbox"-->
              <!--                  :rules="{ required: true, acceptConditions: true }"-->
              <!--                  v-slot="validationContext"-->
              <!--              >-->
              <!--                <b-form-group-->
              <!--                    id="example-input-group-6"-->
              <!--                    class="position-relative margin-medium&#45;&#45;btn"-->
              <!--                    label-for="example-input-6"-->
              <!--                    style="text-align:left;"-->
              <!--                >-->
              <!--                  &lt;!&ndash; <b-form-checkbox-->
              <!--                    id="input-live-6"-->
              <!--                    class="flex-center-flex-start margin-medium&#45;&#45;btn contact-checkbox"-->
              <!--                    v-model="form.privacyPolicyAccepted"-->
              <!--                    :required="true"-->
              <!--                    :state="getValidationState(validationContext)"-->
              <!--                    aria-describedby="input-live-help input-live-6-feedback"-->
              <!--                    name="checkbox-validation"-->
              <!--                  > &ndash;&gt;-->
              <!--                  <div :class="{'is-invalid':  !getValidationState(validationContext)}">-->
              <!--                    <input-->
              <!--                        type="checkbox"-->
              <!--                        true-value="1"-->
              <!--                        false-value="0"-->
              <!--                        v-model="form.privacyPolicyAccepted"-->
              <!--                        name="checkbox-validation"-->
              <!--                        :class="{'is-invalid':  !getValidationState(validationContext)}"-->
              <!--                    />-->
              <!--                  </div>-->
              <!--                  <div-->
              <!--                      class="flex-row white-space&#45;&#45;nowrap icon&#45;&#45;left p-0 text&#45;&#45;main-text"-->
              <!--                  >-->
              <!--                    {{-->
              <!--                      tr("acceptPrivacy")-->
              <!--                    }}<a class="privacy-link icon&#45;&#45;left align-self-center" target="_blank" href="/Adatvédelmi%20tájékoztató%20Meshlin.doc">{{ tr("acceptPrivacy2") }}</a>-->
              <!--                  </div>-->
              <!--                  &lt;!&ndash; </b-form-checkbox> &ndash;&gt;-->
              <!--                </b-form-group>-->
              <!--                <b-form-invalid-feedback-->
              <!--                    class="error-message"-->
              <!--                    :style="{-->
              <!--                      display:-->
              <!--                        validationContext.validated &&-->
              <!--                        !(parseInt(form.privacyPolicyAccepted) === 1)-->
              <!--                          ? 'block'-->
              <!--                          : 'none',-->
              <!--                    }"-->
              <!--                    id="input-live-6-feedback"-->
              <!--                >-->
              <!--                    <span class="form-message-text">{{-->
              <!--                        tr("formErrorPrivacy")-->
              <!--                      }}</span>-->
              <!--                </b-form-invalid-feedback>-->
              <!--              </validation-provider>-->
              <validation-provider
                  name="Checkbox"
                  :rules="{ required: true, acceptConditions: true }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-6"
                    class="position-relative margin-medium--btn"
                    label-for="example-input-6"
                    style="text-align:left;"
                >
                  <!-- <b-form-checkbox
                    id="input-live-6"
                    class="flex-center-flex-start margin-medium--btn contact-checkbox"
                    v-model="form.privacyPolicyAccepted"
                    :required="true"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-live-help input-live-6-feedback"
                    name="checkbox-validation"
                  > -->
                  <div :class="{'is-invalid':  !getValidationState(validationContext)}">
                    <input
                        type="checkbox"
                        true-value="1"
                        false-value="0"
                        v-model="form.privacyPolicyAccepted"
                        name="checkbox-validation"
                        :class="{'is-invalid':  !getValidationState(validationContext)}"
                    />
                  </div>
                  <div
                      class="flex-row white-space--nowrap icon--left text--main-text p-0"
                  >
                    {{
                      tr("acceptPrivacy")
                    }}<a class="privacy-link icon--left align-self-center"
                         href="/hu/adatkezelesi-tajekoztato">{{ tr("acceptPrivacy2") }}</a>
                  </div>
                  <!-- </b-form-checkbox> -->
                  <b-form-invalid-feedback
                      class="error-message"
                      :style="{
                      display:
                        validationContext.validated &&
                        !(parseInt(form.privacyPolicyAccepted) === 1)
                          ? 'block'
                          : 'none',
                    }"
                      id="input-live-6-feedback"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorPrivacy")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div
                  class="flex-row resp-display--none--md align-items-baseline margin-medium--btn text--contact--italic text-left"
              >
                <img class="icon" src="@/assets/images/svgs/required.svg"/>A
                {{ tr("signedRequiredInputText") }}
              </div>
              <div class="flex-row-resp--md flex-center-space-between">
                <b-button
                    type="submit"
                    variant="btn btn--black margin-medium--btn"
                ><img class="icon" src="@/assets/images/svgs/send.svg"/>{{
                    tr("sendMessage")
                  }}
                </b-button>
                <p v-show="sendingError" class="error">Not sent</p>
                <div
                    class="flex-row margin-medium-left resp-display--md align-items-baseline margin-medium--btn text--contact--italic text-left"
                >
                  <img
                      class="icon--contact--required"
                      src="@/assets/images/svgs/required.svg"
                  />A
                  {{ tr("signedRequiredInputText") }}
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
    <div class="contact__map-wrap">
      <GmapMap
          :center="{lat: 47.68879955510231, lng: 17.66476345767082}"
          :zoom="15"
          :options="mapStyle"
          style="width: 100%; height: 555px"
      >
        <!-- <GmapMarker :position="{lat: 46.68943, lng:19.85479}" /> -->
        <gmap-custom-marker :marker="{lat: 47.68861955510231, lng: 17.66476345767082}"
                            @closeclick="window_open=false"
                            :opened="window_open"
                            :position="infowindow"
                            :options="{
                                pixelOffset: {
                                    width: 0,
                                    height: -35
                                }
                            }"
        >
          <img src="@/assets/images/svgs/meshlin_pin.svg"/>
        </gmap-custom-marker>
      </GmapMap>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import * as VueGoogleMaps from 'vue2-google-maps'
import {gmapApi} from 'vue2-google-maps'
import GmapCustomMarker from 'vue2-gmap-custom-marker';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbOyfq0T50CRS1Jo503SdDMTmXe1y4zW4',
    libraries: 'places',
  }
});


export default {
  mixins: [apiHandlerMI],
  components: {
    'gmap-custom-marker': GmapCustomMarker
  },
  methods: {
    showModal() {
      this.$refs['myModal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    getValidationState({validated, valid = null}) {
      return validated ? valid : null;
    },
    onSubmit() {
      const _this = this;
      console.log("Form submitted: ", _this.form);
      _this.post(
          "/contact",
          {
            name: _this.form.name,
            email: _this.form.email,
            subject: _this.form.subject,
            company_name: _this.form.company,
            text: _this.form.message,
            condition: _this.form.privacyPolicyAccepted
                ? "accepted"
                : "not_accepted",
          },
          {},
          () => {
            _this.post(
                "/emailsender/1/1",
                {
                  to: process.env.VUE_APP_EMAIL,
                  data_replace: {
                    name: _this.form.name,
                    email: _this.form.email,
                    subject: _this.form.subject,
                    company_name: _this.form.company,
                    text: _this.form.message,
                  },
                },
                {},
                () => {
                  _this.sendingError = false;
                  _this.setDataLayer("Kapcsolati form", "sikeres");
                  _this.sentMessage = true;
                  _this.form.name = '';
                  _this.form.email = '';
                  _this.form.subject = '';
                  _this.form.company = '';
                  _this.form.message = '';
                  _this.$refs.observer.reset()
                },
                () => {
                  _this.sendingError = true;
                }
            );
          },
          () => {
            _this.sendingError = true;
          }
      );
    },
  },
  props: {
    imageSrc: String,
  },
  computed: {
    google: gmapApi,
    nameState() {
      return !this.form.name || this.form.name.length > 2;
    },
    state() {
      return true;
    },
  },

  created() {
    setTimeout(() => this.sentMessage = false, 4000)
  },
  data() {
    return {
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
      },
      infowindow: {lat: 47.68879955510231, lng: 17.66476345767082},
      window_open: true,
      mapStyle: {
        styles: [
          {
            featureType: "administrative.land_parcel",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c9c6c7"
              }
            ]
          },
          {
            featureType: "landscape",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ff0000"
              }
            ]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ece8e6"
              }
            ]
          },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on"
              },
              {
                color: "#e0dcdb"
              }
            ]
          },
          {
            featureType: "landscape.natural.landcover",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#b2e7ce"
              }
            ]
          },
          {
            featureType: "landscape.natural.terrain",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#60edba"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry.fill",
            stylers: [
              {
                visibility: "on"
              },
              {
                color: "#a5ecc8"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#26ad7c"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                lightness: 100
              },
              {
                visibility: "simplified"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              {
                "color": "#f7f3f0"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                color: "#c9c6c7"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f7f3f0"
              },
              {
                saturation: "0"
              }
            ]
          },
          {
            featureType: "road.highway.controlled_access",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#c9c6c7"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f7f3f0"
              },
              {
                lightness: "54"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "all",
            stylers: [
              {
                color: "#c9c6c7"
              },
              {
                visibility: "off"
              }
            ]
          },
          {
            featureType: "transit",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f7f3f0"
              },
              {
                lightness: "40"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "all",
            stylers: [
              {
                "color": "#c9c6c7"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                visibility: "on"
              },
              {
                lightness: 700
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#f7f3f0"
              }
            ]
          },
          {
            featureType: "transit.station.rail",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ff0000"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#66cc99"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#008d6a"
              }
            ]
          }
        ]
      },
      sendingError: false,
      sentMessage: false,
      form: {
        name: null,
        email: null,
        company: null,
        subject: null,
        message: null,
        privacyPolicyAccepted: null,
      },
    };
  },
};
</script>
