<template>
  <div class="main-section--areaOfUse center flex-column">
    <div class="hero-image-container resp-height-300">
      <div class="hero-dot resp-height-300 zIndex"></div>
      <div class="hero-shadow resp-height-300"></div>
      <img class="hero-image  display-1440 resp-height-300" src="/images/sheetCuttingHero.jpg" alt="sheet cutting">
      <img class="hero-image  display-1440--none resp-height-300" src="/images/sheetCuttingHero1440.jpg" alt="sheet cutting mobile">
    </div>
    <div class="center flex-column sixth-section-container zIndex">
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="-350"
           data-aos-delay="62"
           data-aos-duration="1200"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <div class="title--areaOfUse mb-4 resp-padding-hero-cutting">
          {{ tr('sheetCuttingTitle') }}
        </div>
      </div>
      <div class="item"
           data-aos="fade-right"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="2000"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <p class="text--light-gray text-padding--resp">
          {{ tr('sheetCuttingText') }}
        </p>
      </div>
    </div>
    <div class="flex-row-resp margin-spec-bottom">
      <a
          @click="setDataLayer('lapszabaszat_blokk', 'ajanlat_kerese_gombra_kattintas', '')"
          class="btn btn--green--404 mt-2 margin-lr-auto"
         v-scroll-to="'#offer'"
      >{{tr('offer')}}<img
          class="icon--left"
          src="../assets/images/svgs/arrow-down.svg"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
  },
};
</script>