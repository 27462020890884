<template>
  <div class="background-white">
    <PrivacySection/>
  </div>
</template>


<script>
// @ is an alias to /src
import PrivacySection from "@/components/PrivacySection";

export default {
  name: "Privacy",
  components: {
    PrivacySection,
  },
}
</script>
