<template>
  <div class="main-section--first">
    <div class="mw-1920 margin-lr-auto flex-row">
      <div class="center container--next-material">
        <div class="left-part container">
          <div>
            <div class="item"
                 data-aos="fade-up"
                 data-aos-offset="0"
                 data-aos-delay="2"
                 data-aos-duration="700"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="true"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
              <h1 class="title--big resp-display">
                {{ title }}
              </h1>
            </div>
            <div class="item"
                 data-aos="fade-up"
                 data-aos-offset="0"
                 data-aos-delay="2"
                 data-aos-duration="700"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="true"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
              <h1 class="title--big resp-display--none margin-lr-auto">
                {{ title }}
              </h1>
            </div>
            <div class="item"
                 data-aos="fade-right"
                 data-aos-offset="-200"
                 data-aos-delay="132"
                 data-aos-duration="3500"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="false"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
              <div class="container--animation">
                <div id="wrapper">
                  <div id="line"></div>
                </div>
              </div>
            </div>
            <div class="item"
                 data-aos="fade-up"
                 data-aos-offset="-200"
                 data-aos-delay="32"
                 data-aos-duration="1400"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="true"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
              <h6 class="text next-material-text">
                {{ subTitle }}
              </h6>
            </div>
            <div class="item"
                 data-aos="fade-up"
                 data-aos-offset="-200"
                 data-aos-delay="32"
                 data-aos-duration="1400"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="true"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
              <h6 class="text next-material-text--wide">
                {{ subTitle}}
              </h6>
            </div>
            <div class="item"
                 data-aos="fade-up"
                 data-aos-offset="-400"
                 data-aos-delay="102"
                 data-aos-duration="2000"
                 data-aos-easing="ease-out-cubic"
                 data-aos-mirror="true"
                 data-aos-once="true"
                 data-aos-anchor-placement="top-center">
            <router-link
                v-if="learnMoreButtonMode === 'navigate'"
                @click.native="setDataLayer('Learn more', 'rolunk', 'rolunk')"
                :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_3'))
        "
            >
              <b-button variant="btn btn--black margin-medium--btn"
              >{{ tr('learnMore') }}
              </b-button
              >
            </router-link>

              <a v-if="learnMoreButtonMode === 'anchor'" href="#aboutUsText" v-scroll-to="'#aboutUsText'" class="learn-more-anchor btn btn--black margin-medium--btn"
              >Tovább
              </a>

            </div>
          </div>
        </div>
        <div class="item display-contents"
             data-aos="fade-in"
             data-aos-offset="-200"
             data-aos-delay="0"
             data-aos-duration="1600"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="right-part">
            <img :src="require('../../public/images/' + imageSrc)"/>
          </div>
        </div>
      </div>
    </div>
    <!--    <div class="item"-->
    <!--         data-aos="fade"-->
    <!--         data-aos-offset="0"-->
    <!--         data-aos-delay="0"-->
    <!--         data-aos-duration="3000"-->
    <!--         data-aos-easing="ease-out-cubic"-->
    <!--         data-aos-mirror="false"-->
    <!--         data-aos-once="false"-->
    <!--         data-aos-anchor-placement="top-center">-->
    <div class="margin-lr-auto arrow-vertical-animation resp-display center"
    ><img src="@/assets/images/svgs/arrow-down.png"
    /></div>
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    learnMoreButtonMode:  {
      validator: function (value) {
        return ['anchor', 'navigate'].includes(value);
      }
    }
  },
  computed: {
    title(){
      return this.tr(this.$route.name.endsWith('aboutUs') ?'aboutUsTitle' : 'mainTitle' );
    },
    subTitle(){
      return this.tr(this.$route.name.endsWith('aboutUs') ?'aboutUsText' : 'mainText' );
    }
  }
};
</script>
