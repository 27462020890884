<template>
  <div class="container">
    <div
        class="margin-footer-v row flex-center-space-between container--full divider"
    >
      <img
          class="footer-form resp-display"
          src="../assets/images/svgs/form_footer.svg"
      />
      <ul class="padding-none">
        <img
            class="margin-bottom-footer--logo"
            src="../assets/images/svgs/meshlin_logo.svg"
        />
        <li class="text--footer">Meshlin Composites Zrt.</li>
        <li class="text--footer">9027 Győr, Puskás Tivadar u. 10.</li>
        <li class="margin-bottom-footer"><a  @click="setDataLayer('Lábléc', 'email_gombra_kattintas', '')" class="text--footer" href = "mailto: meshlin@meshlin.com">meshlin@meshlin.com</a></li>
        <li class="margin-bottom-footer"><a  @click="setDataLayer('Lábléc', 'telefonszam_gombra_kattintas', '')" class="text--footer" href="tel:0036-9-632-6474">+ 36 96 326 474</a></li>
        <li class="margin-bottom-footer">

          <a @click="setDataLayer('Lábléc', 'Kattintás', 'www.meslin.com')" class="text--footer-link" href="/">www.meshlin.com</a>
        </li>
        <li class="margin-bottom-footer">
          <a @click="setDataLayer('Lábléc', 'Kattintás', 'Adatvédelmi tájékoztató')" class="text--footer"
             href="/hu/adatkezelesi-tajekoztato">Adatvédelmi tájékoztató</a>
        </li>
        <li class="margin-bottom-footer">
          <a id="CookieBannerSettings" @click="openCookieSettings(true)"
             class="text--footer">Cookie-beállítások</a></li>
      </ul>
      <div
          class="divider--footer center resp-display--none text--menu margin-lr-auto"
      ></div>
      <ul class="padding-none">
        <li class="title--footer margin-bottom-footer">TERMÉKCSALÁDOK</li>
        <li v-for="productFamily in productFamilies"
            :key="tr(productFamily.id)" class="margin-bottom-footer">
          <a @click="setDataLayer('Lábléc', 'termekek', productFamily.name.hu)"
             :href="getProductFamilyHref(tr(productFamily.id))" class="text--footer">{{ tr(productFamily.name) }}</a>
        </li>
      </ul>
      <div
          class="divider--footer center resp-display--none text--menu margin-lr-auto"
      ></div>
      <ul class="padding-none">
        <li class="title--footer margin-bottom-footer">NAVIGÁCIÓ</li>
        <li class="margin-bottom-footer">
          <a class="text--footer" href="/">Home</a>
        </li>
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">About us</a>-->
          <router-link
              @click.native="setDataLayer('Lábléc', 'rolunk', 'rolunk')"
              class="text--menu nav-item"
              :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_3'))
                  "

          >{{ l("main_menu_item_3") }}
          </router-link>
        </li>
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">FAQ</a>-->
          <router-link
              @click.native="setDataLayer('Lábléc', 'GYIK', 'GYIK')"
              class="text--menu nav-item"
              :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_4'))
                  "
          >{{ l("main_menu_item_4") }}
          </router-link>
        </li>
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">Contact</a>-->
          <router-link
              @click.native="setDataLayer('Lábléc', 'kapcsolat', 'kapcsolat')"
              class="text--menu nav-item"
              :to="
                    '/' +
                    $store.state.languageCode +
                    '/' +
                    urlSlug(l('main_menu_item_5'))
                  "
          >{{ l("main_menu_item_5") }}
          </router-link>
        </li>
        <li class="margin-bottom-footer">
          <!--          <a class="text&#45;&#45;footer" href="/">Contact</a>-->
          <a
              @click="setDataLayer('Lábléc', 'pályázatok', 'pályázatok')"
              class="text--menu nav-item"
              href="/data/palyazat.pdf"
          >{{ l("main_menu_item_f6") }}
          </a>
        </li>
      </ul>
    </div>
    <div class="flex-row-resp flex-center-space-between margin--medium-bottom">
      <p class="text--footer">
        Copyright © 2019 Meshlin Composites Zrt. Minden jog fenntartva.
      </p>
      <img src="../assets/images/svgs/footerACGlogo.svg" alt="ACG logo"/>
    </div>
    <go-top :radius="0" :boundary="1600" :right="30" :bottom="106" bg-color="transparent" src="@/assets/images/svgs/back-to-top.svg" class="backToTop">../assets/images/svgs/chevron-top-bright.svg<div class="backToTop"></div></go-top>
  </div>

</template>

<script>
import GoTop from '@inotom/vue-go-top';

export default {
 props: {
   openCookieSettings: Function,
 },
  name: "Footer",
  components: {
    GoTop
  },
  methods: {
    getProductFamilyHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.productFamily`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId,
        },
      }).href;
    },
  },
  computed: {
    productFamilies() {
      return this.$store.state.productFamilies.filter(pf => !pf.hidden);
    },
  }
};
</script>
