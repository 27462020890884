<template>
  <a @click="setDataLayer('Felhasználási területek', 'Kattintás', areaOfUse.name.hu)" class="tile-container--areaOfUse" :href="href">
    <div class="search-box center">
      <div class="tile-animation-container flex-column center">
        <div class="tile-arrow-background">
          <img
              class="tile-arrow-animation"
              src="../assets/images/svgs/tilearrow.svg"
          />
        </div>
      </div>
    </div>
    <img class="tile" :src="`/images/AreaOfUseTiles/${image}`"/>
    <div class="item position-absolute bottom"
         data-aos="fade-down"
         data-aos-offset="-250"
         data-aos-delay="62"
         data-aos-duration="1200"
         data-aos-easing="ease-out-cubic"
         data-aos-mirror="true"
         data-aos-once="true">
      <span class="text--tile text-width-50">{{ tr(title) }}</span>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    id: String,
    image: String,
    title: Object,
  },
  computed: {
    href() {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.areaOfUse`,
        params: {
          language: this.$store.state.languageCode,
          areaOfUseId: this.id,
        },
      }).href;
    },
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(
          (areaOfUse) => this.tr(areaOfUse.id) === this.areaOfUseId
      );
    },
  },
};
</script>