<template>
  <div id="offer" class="main-section--first--cutting overflow-hidden">

    <div class="flex-row-resp--md flex-center-space-between container margin-lr-auto">
      <div class="left-part--cutting">
        <div>
          <div class="title text-left  margin-medium--bottom-30">
            {{ tr("askOffer") }}
          </div>

          <div class="center--resp flex-column">
            <h6 class="text--light-gray text-left margin-medium--bottom-30">
              Amennyiben kitöltötte a korábban letöltöltött táblázatot, vagy csak érdeklődni szeretne, az alábbi űrlap
              segítségével gyorsan felveheti a kapcsolatot velünk. </h6>
            <div class="text-left">
              <h6
                  class="text--light-gray text-left padding-none--resp margin-medium--bottom-30"
              >
                A táblázatot és az ajánlatkérést e-mailben is továbbíthatja nekünk a meshlin@meshlin.com címre.
              </h6>
              <b-button
                  @click="setDataLayer('ajanlatkero_tablazat', 'ajanlatkero_tablazat_letoltes', '')"
                  target="_blank"
                  href="/data/MESHLIN_Lapszabaszat_tablazat.xlsx"
                  variant="btn btn--black-short margin-medium--bottom margin-medium--btn"
              ><img class="icon" src="@/assets/images/svgs/table.svg" alt="table"/>{{
                  tr("tableDownload")
                }}
              </b-button>

              <h6
                  class="text--white font-weight-bold text-left padding-none--resp margin-small--top margin-medium--bottom-30"
              >
                Kitöltött ajánlatkérő táblázat
              </h6>
              <vue-dropzone class="position-relative upload-container" :addRemoveLinks="true" :useCustomSlot="false"
                            ref="myVueDropzone" id="dropzone"
                            :options="dropzoneOptions">
                <div class="file-selector">
                  <a
                      class="w-100 h-100">
                    <img src="@/assets/images/svgs/cloud-upload.svg" alt="upload"/>
                    <div>
                  <span class="text--main-text">Fájlok behúzása vagy <br/><span
                      class="text--light-gray textdecor-underline">feltöltése</span></span>
                    </div>
                  </a>
                </div>
              </vue-dropzone>
              <span v-if="fileError" class="error-color">Fájl feltöltése kötelező.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-part--cutting">
        <div
            class="item"
            data-aos="fade-left"
            data-aos-offset="-150"
            data-aos-delay="62"
            data-aos-duration="800"
            data-aos-easing="ease-out-cubic"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-center"
        >

          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form novalidate @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                  name="Name"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-1"
                    class="position-relative "
                    label-for="example-input-1"
                >
                  <b-form-input
                      :required="true"
                      class="margin-medium--btn"
                      id="input-live-1"
                      v-model="form.name"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-live-1-feedback"
                      :placeholder="tr('name')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-1-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorName")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-2"
                    class="position-relative"
                    label-for="example-input-2"
                >
                  <b-form-input
                      :required="true"
                      type="email"
                      class="margin-medium--btn"
                      id="input-live-2"
                      v-model="form.email"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-2-live-feedback"
                      :placeholder="tr('email')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-2-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorEmail")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Phone"
                  :rules="{ required: false, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-4"
                    class="position-relative"
                    label-for="example-input-4"
                >
                  <b-form-input
                      class="margin-medium--btn"
                      v-model="form.phone"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-4-live-feedback"
                      :placeholder="tr('phone')"
                      trim
                  />
                </b-form-group>
              </validation-provider>
              <validation-provider
                  name="Message"
                  :rules="{ required: true, min: 3 }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-5"
                    class="position-relative"
                    label-for="example-input-5"
                >
                  <b-form-input
                      :required="true"
                      class="margin-medium--btn"
                      id="input-live-5"
                      v-model="form.message"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-live-help input-5-live-feedback"
                      :placeholder="tr('message')"
                      trim
                  />
                  <b-form-invalid-feedback
                      id="input-live-5-feedback"
                      class="error-message"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorMessage")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div v-if="sentMessage" class="sent-message-container d-flex text-left"><img class="icon--faq"
                                                                                           src="@/assets/images/svgs/faq-2-a.svg">
                <div>Köszönjük az ajánlatkérést!!<br/>
                  Hamarosan felvesszük Önnel a kapcsolatot.
                </div>
              </div>

              <validation-provider
                  name="Checkbox"
                  :rules="{ required: true, acceptConditions: true }"
                  v-slot="validationContext"
              >
                <b-form-group
                    id="example-input-group-6"
                    class="position-relative margin-medium--btn"
                    label-for="example-input-6"
                    style="text-align:left;"
                >
                  <!-- <b-form-checkbox
                    id="input-live-6"
                    class="flex-center-flex-start margin-medium--btn contact-checkbox"
                    v-model="form.privacyPolicyAccepted"
                    :required="true"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-live-help input-live-6-feedback"
                    name="checkbox-validation"
                  > -->
                  <div :class="{'is-invalid':  !getValidationState(validationContext)}">
                    <input
                        type="checkbox"
                        true-value="1"
                        false-value="0"
                        v-model="form.privacyPolicyAccepted"
                        name="checkbox-validation"
                        :class="{'is-invalid':  !getValidationState(validationContext)}"
                    />
                  </div>
                  <div
                      class="flex-row white-space--nowrap icon--left text--main-text p-0"
                  >
                    {{
                      tr("acceptPrivacy")
                    }}<a class="privacy-link icon--left align-self-center"
                         href="/hu/adatkezelesi-tajekoztato">{{ tr("acceptPrivacy2") }}</a>
                  </div>
                  <!-- </b-form-checkbox> -->
                  <b-form-invalid-feedback
                      class="error-message"
                      :style="{
                      display:
                        validationContext.validated &&
                        !(parseInt(form.privacyPolicyAccepted) === 1)
                          ? 'block'
                          : 'none',
                    }"
                      id="input-live-6-feedback"
                  >
                    <span class="form-message-text">{{
                        tr("formErrorPrivacy")
                      }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div class="flex-row-resp--md flex-center-space-between">
                <b-button
                    @click="setDataLayer('kerjen_ajanlatot_blokk', 'ajanlatkeres_elkuldese_gombra_kattintas', '')"
                    type="submit"
                    variant="btn btn--green-short margin-medium--btn"
                ><img class="icon" src="@/assets/images/svgs/send-white.svg" alt="send"/>{{
                    tr("sendOffer")
                  }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div class="position--relative margin-large--bottom">
          <img
              class="help-section-form--right--faq"
              src="../assets/images/svgs/form_5.svg"
          />
          <img
              class="help-section-form--right2--faq2"
              src="../assets/images/svgs/meshlin_main-vector.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiHandlerMI from "@/mixins/apiHandlerMI.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {

  mixins: [apiHandlerMI],
  methods: {
    getValidationState({validated, valid = null}) {
      return validated ? valid : null;
    },
    removeAllFiles() {
      return this.$refs.myVueDropzone.removeAllFiles()
    },
    onSubmit() {
      const _this = this;
      console.log("Form submitted: ", _this.form);
      const formData = new FormData();
      formData.append("file", _this.$refs.myVueDropzone.getAcceptedFiles()[0]);

      formData.append("name", _this.form.name);
      formData.append("email", _this.form.email);
      formData.append("phone", _this.form.phone);
      formData.append("text", _this.form.message);
      formData.append("condition", _this.form.privacyPolicyAccepted
          ? "accepted"
          : "not_accepted");

      _this.post(
          "/offer",
          formData,
          {},
          (response) => {
            _this.post(
                "/emailsender/2/1",
                {
                  to: process.env.VUE_APP_EMAIL,
                  attachment_ids: response.data.result.offer.file.id,
                  data_replace: {
                    name: _this.form.name,
                    email: _this.form.email,
                    phone: _this.form.phone,
                    text: _this.form.message,
                  },
                },
                {},
                () => {
                  _this.sendingError = false;
                  _this.setDataLayer("lapszabaszati form", "sikeres");
                  _this.sentMessage = true;
                  _this.form.name = '';
                  _this.form.email = '';
                  _this.form.phone = '';
                  _this.form.message = '';
                  _this.$refs.myVueDropzone.removeAllFiles()
                  _this.$refs.observer.reset()
                },
                () => {
                  _this.sendingError = true;
                  _this.fileError = true;
                  _this.setDataLayer("lapszabaszati form", "sikertelen");

                }
            );
          },
          () => {
            _this.sendingError = true;
            _this.fileError = true;
            _this.setDataLayer("lapszabaszati form", "sikertelen");
          }
      );
    },
  },
  props: {
    imageSrc: String,
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed: {
    nameState() {
      return !this.form.name || this.form.name.length > 2;
    },
    state() {
      return true;
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: `xy`,
        maxFiles: 1,
        includeStyling: false,
        addRemoveLinks: true,
        dictDefaultMessage: "Fájl feltöltése",
        dictRemoveFile: "Fájl törlése"
      },
      CONFIG: {
        apiUrl: process.env.VUE_APP_API_BASE_URL,
      },
      sentMessage: false,
      fileError: false,
      sendingError: false,
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        file: {},
        privacyPolicyAccepted: null,
      },
    };
  },
};
</script>
