<template>
  <div
      class="position-fixed h-100 w-100 zIndex-high"
      id="cookie-policy"
      v-if="needCookieSettings"
  >
    <div class="error-background--cookie">
      <div class="container--full divider nav-bar position-fixed top">
        <div class="container flex-row h-100 flex-center-space-between">
          <img src="../assets/images/svgs/meshlin_logo.svg" alt="Meshlin logo"/>

        </div>
      </div>
      <div
          class="error-floating-objects--cookie center margin-lr-auto flex-column"
      >
        <div class="error-container--cookie container--540 flex-column center">
          <span class="title--cookie margin-medium--top">{{
              tr("cookieTitle")
            }}</span>
          <span
              class="text--main-text margin-medium--top padding-cookie divider cookie-text-menu-container"
          >{{ tr("cookieText") }}
                        <br/><br/>
                      <a class="privacy-link icon--left align-self-center" :href="$router.resolve({name: `${$store.state.languageCode}.privacy`}).href">Itt tekintheti meg az {{
                          tr("acceptPrivacy2")
                        }}</a>
          </span>
          <span
              class="text--main-text margin-medium--top"
              v-show="!changeCookies"
          >{{ tr("cookieText2") }}</span
          >
          <!--        -->
          <div class="mobile-scroll-container">
            <div class="flex-column align-items-baseline-resp" v-show="changeCookies">
              <div class="custom-control w-100 custom-checkbox">
                <input
                    true-value="1"
                    false-value="0"
                    v-model="cookies.required"
                    type="checkbox"
                    class="custom-control-input"
                    id="required_chbx"
                    disabled
                />
                <label
                    class="d-flex justify-content-between custom-control-label m-0 text--footer--wide margin-medium--right text-left"
                    for="required_chbx"
                >Müködéshez szükséges cookie-k<a v-b-toggle.accordion-1 @click="!cookieAccordion1">
                  <div v-show="cookieAccordion1 === false"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron navbar-accordion-chevron--cookie">
                  </div>
                  <div v-show="cookieAccordion1 === true"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron-open navbar-accordion-chevron-open--cookie">
                  </div>
                </a></label
                >
                <b-collapse v-model="cookieAccordion1" id="accordion-1">
                  <div class="text--main-text--small text-left pt-2">Ezek a cookie-k biztosítják a weboldal megfelelő
                    működését, megkönnyítik annak használatát, nélkülük a weboldal kényelmes használata nehezebben vagy
                    egyáltalán nem biztosítható.
                  </div>
                </b-collapse>
              </div>
              <div class="custom-control w-100 custom-checkbox">
                <input
                    true-value="1"
                    false-value="0"
                    v-model="cookies.statistic"
                    type="checkbox"
                    class="custom-control-input"
                    id="statistics_chbx"
                />
                <label
                    class="d-flex justify-content-between custom-control-label m-0 text--footer--wide margin-medium--right white-space--nowrap"
                    for="statistics_chbx"
                >Statisztikai cookie-k <a v-b-toggle.accordion-2 @click="!cookieAccordion2">
                  <div v-show="cookieAccordion2 === false"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron navbar-accordion-chevron--cookie">
                  </div>
                  <div v-show="cookieAccordion2 === true"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron-open navbar-accordion-chevron-open--cookie">
                  </div>
                </a></label
                >
                <b-collapse v-model="cookieAccordion2" id="accordion-2">
                  <div class="text--main-text--small text-left pt-2">A statisztikai cookie-k olyan általunk vagy
                    harmadik fél által kezelt cookie-k, amelyek lehetővé teszik számunkra a felhasználók számának és az
                    oldalon történő böngészésük mérését és így a weboldalon nyújtott szolgáltatás használatának
                    statisztikai elemzését.
                  </div>
                </b-collapse>
              </div>
              <div class="custom-control w-100  custom-checkbox">
                <input
                    true-value="1"
                    false-value="0"
                    v-model="cookies.marketing"
                    type="checkbox"
                    class="custom-control-input"
                    id="marketing_chbx"
                />
                <label
                    class=" d-flex justify-content-between custom-control-label m-0 text--footer--wide margin-medium--right white-space--nowrap"
                    for="marketing_chbx"
                >Marketing cookie-k <a v-b-toggle.accordion-3 @click="!cookieAccordion3">
                  <div v-show="cookieAccordion3 === false"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron navbar-accordion-chevron--cookie">
                  </div>
                  <div v-show="cookieAccordion3 === true"
                       class="icon--left m-md-1 align-self-center navbar-accordion-chevron-open navbar-accordion-chevron-open--cookie">
                  </div>
                </a></label
                >
                <b-collapse v-model="cookieAccordion3" id="accordion-3">
                  <div class="text--main-text--small text-left pt-2">Ezek a cookie-k információkat gyűjtenek az Ön
                    böngészési szokásairól annak érdekében, hogy Ön az érdeklődésének megfelelő releváns hirdetésekkel
                    találkozzon a weboldalon.<br/><br/>
                    A marketing cookie-k megjegyzik, ha Ön meglátogatott egy weboldalt, és ezeket az információkat
                    megosztják más szervezetekkel, mint pl. a hirdetőkkel.
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
          <!--        -->
          <div class="resp-row">
            <b-button
                type="button"
                v-if="!changeCookies"
                variant="btn btn--black mb-sm-1 margin-medium--btn button-margin"
                @click="showCookieSettings()"
            >{{ tr("cookieOptions") }}
            </b-button>
            <b-button
                type="button"
                v-if="changeCookies"
                variant="btn btn--black mb-sm-1 margin-medium--btn button-margin"
                @click="saveCookieSettings()"
            >{{ tr("cookieOptionSave") }}
            </b-button>

            <b-button
                type="button"
                variant="btn btn--green--cookie cookie--accept margin-medium--btn button-margin margin-lr-auto"
                @click="acceptCookies()"
            >{{ tr("cookieAccept") }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      needCookieSettings: true,
      changeCookies: false,
      cookieAccordion1: false,
      cookieAccordion2: false,
      cookieAccordion3: false,
      cookies: {
        required: "1",
        marketing: this.$cookies.get("marketing_cookie") || '0',
        statistic: this.$cookies.get("statistic_cookie") || '0',
      },
      expiredDate: 60 * 60 * 24 * 30,
    };
  },
  mounted() {
    var _this = this;

    if (parseInt(this.$cookies.get("required_cookie")) === 1 || this.$route.name.endsWith('.privacy')) {
      _this.closeCookieSetting();
      // this.setGTMScriptTag();
    }
  },
  props: {
    openCookieModal: Boolean,
    closeCookieModal: Function,
openCookieChanges: Boolean
  },
  watch: {
    openCookieModal(newValue) {
      this.needCookieSettings = newValue
    },
    openCookieChanges(newValue) {
      this.changeCookies = newValue
      this.cookies.marketing = this.$cookies.get("marketing_cookie") || '0'
      this.cookies.statistic = this.$cookies.get("statistic_cookie") || '0'
    },
  },
  methods: {
    closeCookieSetting: function () {
      document.getElementsByTagName("body")[0].style.removeProperty("overflow");
      this.needCookieSettings = false;
      this.closeCookieModal();
    },
    languageChange: function () {
      this.$forceUpdate();
    },
    acceptCookies() {
      // required_cookie, marketing_cookie, statistic_cookie
      this.$cookies.set("required_cookie", 1, this.expiredDate);
      this.$cookies.set("marketing_cookie", 1, this.expiredDate);
      this.$cookies.set("statistic_cookie", 1, this.expiredDate);
      this.closeCookieSetting();      
    },
    showCookieSettings() {
      this.changeCookies = true;
    },
    saveCookieSettings() {
      this.$cookies.set("required_cookie", 1, this.expiredDate);
      this.$cookies.set("marketing_cookie", parseInt(this.cookies.marketing) === 1 ? 1 : 0, this.expiredDate);
      this.$cookies.set("statistic_cookie", parseInt(this.cookies.statistic) === 1 ? 1 : 0, this.expiredDate);

      this.closeCookieSetting();

      if (
          parseInt(this.cookies.marketing) === 1 ||
          parseInt(this.cookies.statistic) === 1
      ) {        
        document.getElementById("main-content").removeAttribute("style");
      }
    }, 
  },
};
</script>