export const defaultLanguageCode = 'hu';
export const languages = [
  {
    code: "hu",
    targetLink: "/hu"
  },
  {
    code: "en",
    targetLink: "/en"
  }
]