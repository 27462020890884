<template>
  <div>
    <SheetCuttingHero />
    <SheetCuttingMain />
    <SheetCuttingForm/>
    <can-we-help-section/>
  </div>
</template>


<script>
// @ is an alias to /src
import SheetCuttingHero from "@/components/sheetCuttingHero.vue";
import SheetCuttingMain from "@/components/sheetCuttingMain.vue";
import SheetCuttingForm from "@/components/sheetCuttingForm.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection";

export default {
  name: "SheetCutting",
  components: {
    CanWeHelpSection,
    SheetCuttingHero,
    SheetCuttingMain,
    SheetCuttingForm
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Lapszabászat',
      desc: 'Cégünk lapszabászati munkákat is vállal rövid, akár pár napos határidővel, nálunk vásárolt, illetve hozott anyagokból. Keressen minket, ha jó minőségű alapanyagokból szeretné elkészíteni bútorait.',
      keywords: 'Lapszabászat',
      ogType: 'website',
      ogTitle: 'Meshlin - Lapszabászat',
      ogImage: '/images/seo-logo.jpg',
      ogImageWidth: 600,
      ogImageHeight: 315,
      ogDesc:'Cégünk lapszabászati munkákat is vállal rövid, akár pár napos határidővel, nálunk vásárolt, illetve hozott anyagokból. Keressen minket, ha jó minőségű alapanyagokból szeretné elkészíteni bútorait.'
    })
  },
};
</script>