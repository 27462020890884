<template>
  <div class="main-section--product mw-1920 center flex-column">
    <div class="hero-image-container"><div class="hero-dot"></div><div class="hero-shadow--revert"></div><img class="hero-image" :src="`/images/ProductFamilyTiles/${productFamily.heroImage}`" :alt="tr(productFamily.name)"></div>
    <div class="center flex-column resp-sm-top-margin-hero resp-xs-top-margin resp-md-top-margin sixth-section-container--product">
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="0"
           data-aos-delay="32"
           data-aos-duration="1000"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
      <div class="title margin-spec-top margin-medium--bottom-30">
        {{ tr(productFamily.name) }}
      </div>
      </div>
      <div class="item"
           data-aos="fade-right"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="2000"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
      <p class="text--white font-weight-bold">
        {{ tr(productFamily.description) }}
      </p>
      </div>
      <div class="item"
           data-aos="fade-left"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="2000"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
      <p class="text--light-gray resp-display text-padding--resp">
        {{ tr(productFamily.description2) }}      </p>
      </div>
      <div class="item"
           data-aos="fade-right"
           data-aos-offset="-150"
           data-aos-delay="62"
           data-aos-duration="2000"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <p class="text--light-gray text-padding--resp">
          {{ tr(productFamily.description3) }}
        </p>
      </div>
    </div>
    <div class="flex-row-resp margin-spec-bottom">
<!--      <b-button @click="setDataLayer('Katalógus letöltése', 'kattintás', productFamily.name.hu)" :href="`/sample-3pp-${$store.state.languageCode}.pdf`" class="btn btn&#45;&#45;gray button-margin"-->
<!--      ><img class="icon" src="../assets/images/svgs/comet.svg"/>{{ tr('catalogButton') }}-->
<!--      </b-button-->
<!--      >-->
      <router-link
          @click.native="setDataLayer('Lépjen kapcsolatba velünk', 'Kattintás', `${productFamily.name.hu}-felső`)"
          class="btn btn--green margin-medium--btn--hero margin-lr-auto"
          :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_5'))
        "
      ><img
          class="icon"
          src="../assets/images/svgs/envelope-open.svg"
      />{{ tr('contactButton') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
}
</script>