<template>
  <div class="main-section--areaOfUse center flex-column">
    <div class="hero-image-container resp-height-300">
      <div class="hero-dot resp-height-300 zIndex"></div>
      <div class="hero-shadow resp-height-300"></div>
      <img class="hero-image display-1440 resp-height-300" :src="`/images/AreaOfUseTiles/${areaOfUse.heroImage}`" :alt="tr(areaOfUse.name)">
      <img class="hero-image display-1440--none resp-height-300" :src="`/images/AreaOfUseTiles/${areaOfUse.heroImageSmall}`" :alt="`${tr(areaOfUse.name)}-small`">
    </div>
    <div class="center flex-column sixth-section-container zIndex">
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="-350"
           data-aos-delay="62"
           data-aos-duration="1200"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <div class="title--areaOfUse resp-padding-hero">
          {{ tr(areaOfUse.name) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
  },
  computed: {
    areaOfUseId() {
      return this.$router.currentRoute.params.areaOfUseId;
    },
    areaOfUse() {
      return this.$store.state.areasOfUse.find(
          (areaOfUse) => this.tr(areaOfUse.id) === this.areaOfUseId
      );
    },
  },
};
</script>