<template>
  <a @click="setDataLayer('Termékcsalád', 'Kattintás', productFamily.name.hu)" class="tile-container" :href="href">
    <div class="search-box">
<!--      <img src="../assets/images/svgs/info.svg"/>-->
      <div class="tile-animation-container flex-column center">
        <div class="tile-arrow-background">
          <img
              class="tile-arrow-animation"
              src="../assets/images/svgs/tilearrow.svg"
          />
        </div>
        <p class="text--tile--animation-text">
          {{ description }}
        </p>
      </div>
    </div>
    <img class="tile" :src="`/images/ProductFamilyTiles/${image}`"/>
      <span class="text--tile">{{ title }}</span>
  </a>
</template>

<script>
export default {
  props: {
    id: String,
    image: String,
    title: String,
    description: String,
  },
  computed: {
    href() {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.productFamily`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId: this.id,
        },
      }).href;
    },
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
};
</script>