<template>
  <div class="main-section--second  resp-padding-top-80 divider--cutting background-white center flex-column">
    <div class="container--730 ">
      <div class="item"
           data-aos="fade-down"
           data-aos-offset="-250"
           data-aos-delay="62"
           data-aos-duration="800"
           data-aos-easing="ease-out-cubic"
           data-aos-mirror="true"
           data-aos-once="true"
           data-aos-anchor-placement="top-center">
        <h2 class="title--black center--resp margin-spec-top margin-spec-bottom">
          {{ tr('whyChooseUs') }}
        </h2>
      </div>
    </div>
    <div class="divider--product container">
      <div class="flex-row-resp flex-center-space-between margin-spec-bottom">
        <div class="item"
             data-aos="fade-right"
             data-aos-offset="-200"
             data-aos-delay="92"
             data-aos-duration="1400"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column"
          ><img
              class="tile--composite-white"
              src="../assets/images/svgs/form1-cutting.svg"
          /><span class="text--black--narrow margin-small--top">{{ tr('advantageCut1') }}</span></div
          >
        </div>
        <div class="item"
             data-aos="fade-right"
             data-aos-offset="-200"
             data-aos-delay="62"
             data-aos-duration="700"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column">
            <img
                class="tile--composite-white"
                src="../assets/images/svgs/form2-cutting.svg"
            /><span class="text--black--narrow margin-small--top">{{ tr('advantageCut2') }}</span></div
          >
        </div>
        <div class="item"
             data-aos="fade-left"
             data-aos-offset="-200"
             data-aos-delay="62"
             data-aos-duration="700"
             data-aos-easing="ease-out-cubic"
             data-aos-mirror="true"
             data-aos-once="true"
             data-aos-anchor-placement="top-center">
          <div class="center flex-column">
            <img
                class="tile--composite-white"
                src="../assets/images/svgs/form3-cutting.svg"
            /><span class="text--black--narrow margin-small--top">{{ tr('advantageCut3') }}</span></div
          >
        </div>
      </div>
    </div>

    <h2 class="title--black center--resp margin-spec-top margin-spec-bottom">
      {{ tr('services') }}
    </h2>
    <div class="flex-row flex-wrap flex-center-space-between container ">
      <div

      >
        <div class="flex-column">
          <img class="margin-small--horizontal m-0 service-icon" src="/images/fieldsOfUse/cutting-1.svg" alt="cutting info 1"/>
          <span class="center text--footer--wide">{{
               tr('cutting-1')

            }}</span>
        </div>
      </div><div

      >
        <div class="flex-column">
          <img class="margin-small--horizontal m-0 service-icon" src="/images/fieldsOfUse/cutting-2.svg" alt="cutting info 2"/>
          <span class="center text--footer--wide">{{
              tr('cutting-2')
            }}</span>
        </div>
      </div>
      <div

      >
        <div class="flex-column">
          <img class="margin-small--horizontal m-0 service-icon" src="/images/fieldsOfUse/cutting-3.svg" alt="cutting info 3"/>
          <span class="center text--footer--wide">{{
              tr('cutting-3')
            }}</span>
        </div>
      </div> <div

      >
        <div class="flex-column">
          <img class="margin-small--horizontal m-0 service-icon" src="/images/fieldsOfUse/cutting-4.svg" alt="cutting info 4"/>
          <span class="center text--footer--wide">{{
              tr('cutting-4')
            }}</span>
        </div>
      </div>
      <div

      >
        <div class="flex-column">
          <img class="margin-small--horizontal m-0 service-icon" src="/images/fieldsOfUse/cutting-5.svg" alt="cutting info 5"/>
          <span class="center text--footer--wide">{{
              tr('cutting-5')
            }}</span>
        </div>
      </div>
    </div>
    <div class="flex-row-resp">
      <a
          @click="setDataLayer('szolgaltatasok_blokk', 'ajanlat_kerese_gombra_kattintas', '')"
          class="btn btn--green--404 margin-medium--btn margin-medium--bottom margin-lr-auto"
         v-scroll-to="'#offer'"
      >{{tr('offer')}}<img
          class="icon--left"
          src="../assets/images/svgs/arrow-down.svg"
      /></a>
    </div>
  </div>
</template>

<script>
export default {

};
</script>
