<template>
  <div>
    <NextMaterialSection learnMoreButtonMode="navigate" image-src="main1.jpg" />
    <AboutCompositeSection />
    <ProductFamilySection2 info-width="wide" />
    <AreaOfUseSection />
<!--    <WhoChooseUsSection />-->
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import NextMaterialSection from "@/components/NextMaterialSection.vue";
import AboutCompositeSection from "@/components/AboutCompositeSection.vue";
import ProductFamilySection2 from "@/components/ProductFamilySection.vue";
import AreaOfUseSection from "@/components/AreaOfUseSection.vue";
// import WhoChooseUsSection from "@/components/WhoChooseUsSection.vue";
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";

export default {
  name: "HomeHu",
  components: {
    NextMaterialSection,
    AboutCompositeSection,
    ProductFamilySection2,
    AreaOfUseSection,
    // WhoChooseUsSection,
    CanWeHelpSection,
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin',
      desc: 'A Meshlin innovatív anyagai forradalmasítják a gyártási és elkészítési folyamatokat és egy megfizethető, környezettudatos alternatívát kínálnak az iparág számára.',
      keywords: 'home',
      ogType: 'website',
      ogTitle: 'Meshlin',
      ogImage: '/images/seo-logo.jpg',
      ogImageWidth: 600,
      ogImageHeight: 315,
      ogDesc:'A Meshlin innovatív anyagai forradalmasítják a gyártási és elkészítési folyamatokat és egy megfizethető, környezettudatos alternatívát kínálnak az iparág számára.'
    })
  },
};
</script>