<template>
  <div class="container--full mw-1920 flex-row background-white">
    <div class="container--33 flex-column resp-display">
      <GalleryProduct ref="productGallery"/>
    </div>
    <div class="container--67">
      <div class="flex-column">
        <div class="text-container--tile-product text-align--left">
          <div class="flex-row">
            <div>
              <div class="mw-100">
                <p class="text--green">{{ tr(productFamily.name) }}</p>
                <h2 class="title--black text-align--left">
                  {{ tr(product.name) }} <span v-if="tr(product.nameAddition)"
                                               class="margin-small--horizontal--icon"> + </span>
                  {{ tr(product.nameAddition) }}
                </h2>
                <span class="text--menu margin-small--top">{{
                    tr(product.description)
                  }}
              </span>
              </div>
              <div class="tile-info-illustration--product2">
                <img src="@/assets/images/svgs/graf5.svg"/>
              </div>
              <router-link
                  @click.native="setDataLayer('Lépjen kapcsolatba velünk', 'Kattintás', product.name.hu)"
                  class="btn btn--green-short--resp margin-medium--btn"
                  :to="'/' + $store.state.languageCode + '/' + urlSlug(l('main_menu_item_5'))"
              >
                {{ tr('contactButton') }}
              </router-link>
              <div class="slide-gallery">
                <vueper-slides fade autoplay :arrows="false" fixedHeight="100vw" :touchable="true">
                  <vueper-slide
                      v-for="(slide, i) in slides"
                      :key="i"
                      :image="slide.image"
                  />
                </vueper-slides>
              </div>
              <b-button
                  @click="$refs.productGallery.open(); setDataLayer('galeria', 'megnyitás', product.name.hu)"
                  class="text--green  bg-transparent white-space--nowrap padding-none dflex button-margin--product  resp-display">
                {{ tr('galleryButton') }}
                <img class="icon--navbar" src="@/assets/images/svgs/search-plus.svg"/>
              </b-button>
            </div>
            <div class="tile-info-illustration--product">
              <img src="@/assets/images/svgs/graf5.svg"/>
            </div>
          </div>
          <div v-if="product.properties && product.properties.length || product.specifications"
               class="flex-row margin-medium--bottom flex-center-space-between margin-large--top--resp">
            <h4 class="title--black-small"> {{ tr('productDetails') }}</h4>
<!--            <a @click="setDataLayer('Technikai adatlap letöltése\n', 'Kattintás', product.name.hu)"-->
<!--               v-if="product.specifications" :href="tr(product.specifications)" target="_blank"-->
<!--               class="btn btn&#45;&#45;gray resp-display"-->
<!--            ><img class="icon" src="@/assets/images/svgs/comet.svg"/>{{ tr('techButton') }}</a-->
<!--            >-->
          </div>
          <table v-if="product.properties && product.properties.length" class="table">
            <tr v-for="property in product.properties" :key="tr(property.name)">
              <td class="text--black text-align--center">{{ tr(property.name) }}</td>
              <td class="text--green--narrow">{{ property.value }}</td>
            </tr>
          </table>
<!--          <b-button @click="setDataLayer('Technikai adatlap letöltése', 'Kattintás', product.name.hu)"-->
<!--                    class=" margin-lr-auto margin-small&#45;&#45;top btn btn&#45;&#45;gray margin-medium&#45;&#45;bottom resp-display&#45;&#45;none"-->
<!--          ><img-->
<!--              class="icon"-->
<!--              src="@/assets/images/svgs/comet.svg"-->
<!--          />{{ tr('techButton') }}-->
<!--          </b-button>-->


          <div
              class="center resp-row margin-medium--btn--resp unique-solution-container"
          >
            <img class="margin-small--right" src="@/assets/images/svgs/uniqueForm.svg"/>
            <div class="left flex-column">
              <span class=" text--black--narrow padding-none--vertical"
              >{{ tr('uniqueSolutionButtonText') }}</span>
              <div class="navbar-arrow-animation  ">
                <router-link
                    @click.native="setDataLayer('Egyedi igenyem van', 'Kattintás', product.name.hu)"
                    class="flex-row text--navbar-link--black button-margin--product"
                    :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_5'))
        "
                ><span class="text--navbar-link--black">{{ tr('uniqueSolutionButton') }}</span>
                  <div
                      class="icon--navbar navbar-arrow--dark">
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="flex-column margin-large--top--product">
            <div>
              <h4 class="title--black-small margin-medium--bottom">{{ tr('colorVariants') }}</h4>
              <!--              <div class="scroll-container&#45;&#45;product">-->
              <!--                <div-->
              <!--                    class="social-icon-container&#45;&#45;product margin-lr-auto"-->
              <!--                >-->
              <!--                  <div class="flex-row">-->
              <!--                    <div v-for="color in product.colors" :key="color.id">-->
              <!--                      <div class="flex-column">-->
              <!--                        <img :src="`/images/Colors/${color.image}`"/>-->
              <!--                        <span-->
              <!--                            class="center margin-large&#45;&#45;bottom&#45;&#45;resp text&#45;&#45;footer&#45;&#45;wide"-->
              <!--                        >{{ tr(color.name) }}</span-->
              <!--                        >-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              <div
                  class="center resp-row margin-medium--btn--resp unique-solution-container"
              >
                <img class="margin-small--right" src="@/assets/images/svgs/color-rectangle.png"/>
                <div class="left flex-column">
              <span class=" text--black--narrow padding-none--vertical"
              >{{ tr('colorSolutionButtonText') }}</span>
                  <div class="navbar-arrow-animation  ">
                    <router-link
                        @click.native="setDataLayer('Egyedi igenyem van', 'Kattintás', product.name.hu)"
                        class="flex-row text--navbar-link--black button-margin--product"
                        :to="
        '/' +
        $store.state.languageCode +
        '/' +
        urlSlug(l('main_menu_item_5'))
        "
                    ><span class="text--navbar-link--black">{{ tr('colorSolutionButton') }}</span>
                      <div
                          class="icon--navbar navbar-arrow--dark">
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="more-product">
              <h4 class="title--black-small margin-medium--bottom">{{ tr('areaOfUse') }}</h4>
              <div class="flex-row margin-min-10 flex-wrap">
                <div
                    v-for="fieldOfUse in product.fieldsOfUse"
                    :key="fieldOfUse.id"
                >
                  <div class="flex-column max-width--150 text-center">
                    <img class="margin-small--horizontal--10 service-icon--130 fieldOfUseProductImg"
                         :src="`/images/fieldsOfUse/${fieldOfUse.image}`"/>
                    <span class="center text--footer--wide margin-medium--bottom">{{
                        tr(fieldOfUse.name)
                      }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="more-product">
            <div class="flex-column">
              <p v-if="products.length" class="text--green">{{ tr('more') }} {{ tr(productFamily.name) }}</p>
              <div class="divider--product margin-medium--bottom-30" v-for="product in products"
                   :key="tr(product.id)">
                <h2 class="title--black flex-row text-align--left">
                  {{ tr(product.name) }} <span v-if="tr(product.nameAddition)"
                                               class="margin-small--horizontal--icon d-flex align-items-center"> + </span>
                  {{ tr(product.nameAddition) }}
                  <!--                  <b-button v-b-popover.hover.top="tr(product.messagePopover)" class="btn btn&#45;&#45;popover">-->
                  <!--                    <img class="icon&#45;&#45;navbar" src="@/assets/images/svgs/info2.svg"/>-->
                  <!--                  </b-button>-->
                </h2>
                <h6 class="text--menu margin-small--top">
                  {{ tr(product.description) }}
                </h6>
                <div class="navbar-arrow-animation">
                  <a @click="setDataLayer('Részletek', 'Kattintás', product.name.hu)"
                     class="left text--navbar-link--black" :href="getHref(tr(product.productFamilyId) ,tr(product.id))"
                  >{{ tr('details') }}
                    <div
                        class="icon--navbar navbar-arrow--dark">
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryProduct from "@/components/GalleryProduct";
import {VueperSlide, VueperSlides} from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  components: {
    GalleryProduct,
    VueperSlides,
    VueperSlide
  },
  data: () => ({
    slides: [
      {
        // You can also provide a URL for the image.
        image: '/images/product2.jpg'
      },
      {
        // You can also provide a URL for the image.
        image: '/images/product1.jpg'
      },
      {
        // You can also provide a URL for the image.
        image: '/images/product3.jpg'
      }
    ]
  }),
  props: {
    products: Array
  },
  methods: {
    getHref(productFamilyId, productId) {
      return this.$router.resolve({
        name: `${this.$store.state.languageCode}.product`,
        params: {
          language: this.$store.state.languageCode,
          productFamilyId,
          productId
        },
      }).href;
    }
  },
  computed: {
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(
          (productFamily) => this.tr(productFamily.id) === this.productFamilyId
      );
    },
    productId() {
      return this.$router.currentRoute.params.productId;
    },
    product() {
      return this.productFamily.products.find(
          (product) => this.tr(product.id) === this.productId
      );
    },
  },
};
</script>