<template>
  <ContactSection/>
</template>

<script>
import ContactSection from "@/components/ContactSection";

export default {
  name: "Contact",
  components: {
    ContactSection,
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Kapcsolat',
      desc: 'Küldjön nekünk üzenetet!',
      keywords: 'Contact',
      ogType: 'website',
      ogTitle: 'Meshlin - Kapcsolat',
      ogImage: '/images/main2.jpg',
      ogImageWidth: 600,
      ogImageHeight: 315,
      ogDesc:'Küldjön nekünk üzenetet!'
    })
  },
}
</script>

<style scoped>

</style>