<template>
  <div>
    <ProductFamilyHero />
    <ProductsInfoTileSection title="Termékcsalád tagjai" class="resp-md-top-margin resp-sm-top-margin" :products="productFamily.products"/>
    <ProductFamilySection2 filter-current-family />
    <CanWeHelpSection />
  </div>
</template>


<script>
// @ is an alias to /src
import CanWeHelpSection from "@/components/CanWeHelpSection.vue";
import ProductsInfoTileSection from "@/components/ProductsInfoTileSection.vue";
import ProductFamilyHero from "@/components/ProductFamilyHero.vue";
import ProductFamilySection2 from "@/components/ProductFamilySection.vue";

export default {
  name: "ProductFamily",
  components: {
    CanWeHelpSection,
    ProductsInfoTileSection,
    ProductFamilyHero,
    ProductFamilySection2,
  },
  computed: {
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(productFamily => this.tr(productFamily.id) === this.productFamilyId)
    }
  },
  mounted(){
    this.setMetaTags({
      title: 'Meshlin - Termékcsaládaink - ' + this.productFamily.name.hu,
      desc: this.tr(this.productFamily.description),
      keywords: 'Termékcsalád',
      ogType: 'website',
      ogTitle: 'Meshlin - Termékcsaládaink - ' + this.productFamily.name.hu,
      ogImage: '/images/ProductFamilyTiles/' + this.productFamily.image,
      ogImageWidth: 470,
      ogImageHeight: 470,
      ogDesc:this.tr(this.productFamily.description),
    })
  },
};
</script>