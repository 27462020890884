<template>
  <div class="flex-column mw-1920">
    <gallery :items="images" :origin="origin" @close="origin = null">
      <span ref="prev">efzuhefzefzef</span>
    </gallery>
    <div class="item"
         data-aos="fade-right"
         data-aos-offset="-350"
         data-aos-delay="62"
         data-aos-duration="800"
         data-aos-easing="ease-out-cubic"
         data-aos-mirror="true"
         data-aos-once="true"
         data-aos-anchor-placement="top-center">
      <div v-for="(image, index) in images" :key="image.id"
           class="tile-container--product">
        <div
            class="tile gallery-product"
            @click="origin = index; setDataLayer('galeria', 'megnyitás', product.name.hu)"
            :style="{
              backgroundImage: 'url(' + image.href + ')',
              width: '33.333vw',
              height: '33.333vw',
            }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-blueimp-gallery/dist/vue-blueimp-gallery.css";
import gallery from "vue-blueimp-gallery";
import slashImageUrl from "@/assets/images/svgs/meshlin_main-vector-gallery.svg";

export default {
  name: "App",
  components: {
    gallery,
  },
  data: function () {
    return {
      origin: null,
    };
  },
  methods: {
    open() {
      this.origin = 0;
    }
  },
  computed: {
    images() {
      return this.product.images.map((image, index, array) => ({
        ...image,
        description: `<div class="flex-row margin-medium--bottom"><div class="image-gallery-text-container">${image.description}</div><div class="center image-gallery-number"><div class="text--gallery">${index + 1}</div><img class="galleryImg" src="${slashImageUrl}"><div class="text--gallery--narrow">${array.length}</div></div></div>`
      }));
    },
    productFamilyId() {
      return this.$router.currentRoute.params.productFamilyId;
    },
    productFamily() {
      return this.$store.state.productFamilies.find(
          (productFamily) => this.tr(productFamily.id) === this.productFamilyId
      );
    },
    productId() {
      return this.$router.currentRoute.params.productId;
    },
    product() {
      return this.productFamily.products.find(
          (product) => this.tr(product.id) === this.productId
      );
    },
  },
};
</script>
